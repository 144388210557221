#footer {
	color: white;
	padding: 15px 0;
	font-size: 15px;
	float: left;
	width: 100%;

	@media screen and (max-width: $tablet) {
		height: auto;
		background: $primary-color;
	}

	.box_footer {
		address {
			margin-bottom: 0;

			h2 {
				margin-bottom: 10px;
			}

			table {
				float: left;
				width: calc(100% - 160px) !important;

				@media screen and (max-width: $tablet) {
					width: 100% !important;
				}

				a {
					font-style: normal;
					color: white;

					&:hover {
						color: white;
					}
				}

				tbody {
					tr {
						td {
							width: 33%;
							float: left;
							display: block;

							@media screen and (max-width: $tablet) {
								width: 100%;
								margin-bottom: 10px;
							}
						}
					}
				}
			}

			#tripadvisor {
				float: left;
			}
		}
	}


	p {
		font-size: 13px;
		line-height: 18px;
		color: white;
	}

	.footer_mobile {
		padding: 10px 0;
		display: none;
	}
}

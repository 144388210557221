@charset "utf-8";
@font-face {
		font-family: TradeGothicLT18;
		src: url(TradeGothicLT18.woff);
}
@font-face {
		font-family: TradeGothicLTBold20;
		src: url(TradeGothicLTBold20.woff);
}
@font-face {
		font-family: 'FuturaBkBT';
		src: url(FuturaBkBT.woff);
}

body {
	line-height: 18px;
	font-size: 13px;
	font-family: Helvetica,Arial,sans-serif;
	margin: 0px;
	padding: 0px;
	color: #555;
		background: #eee!important;/*fcfaeb;*/
}

h1,h2,h3,h4,h5,h6,p {
	padding: 0;
	margin: 0;
}

#header ul, #footer .nav_top ul, .box_partner ul{
	margin: 0;
	padding: 0;
	list-style: none;
}

h1 {
	font-size: 19px;
}

h2 {
	font-size: 17px;
}

h3 {
	font-size: 15px;
}

h4,h5,h6 {
	font-size: 14px;
}

img {
	border: 0px;
	outline: none;
}

a {
	color: #696969;
	text-decoration: none;
	outline: none;
}

a:hover {
	text-decoration: underline;
}

blockquote,q {
	quotes: none;
}

blockquote:before,blockquote:after,q:before,q:after {
	content: none;
}

/* remember to define focus styles! */
:focus {
	outline: 0;
}
input,textarea,select{
	border-radius: 3px;
	border: 1px solid #D3D3D3;
}
input:focus,textarea:focus {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 4px #E5E5E5;
	overflow: hidden;
}
/* remember to highlight inserts somehow! */
ins {
	text-decoration: none;
}

del {
	text-decoration: line-through;
}

.highlight{
	background-color: yellow;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.clear {
	clear: both;
}

.clearL {
	clear: left;
}

.clearR {
	clear: right;
}
.hidden{display: none;}
#flashMessage {
	margin: 0 auto;
	width: 100%;
	padding: 5px;
	position: fixed;
	top: 0;
	z-index: 10000;
}
#flashMessage span{
	border-radius: 3px;
	padding-left: 32px;
	display: block;
	width: 470px;
	line-height: 35px;
	margin: 0 auto;
	background: #D31010;
	color: #fff;
	box-shadow: 0 0 3px #aaa;
}
#flashMessage.success span {
	background-image: url('img/success.png');
	background-position:  8px center;
	background-repeat: no-repeat;
}

#flashMessage.error span {
	background-image: url('img/error.png');
	background-position:  8px center;
	background-repeat: no-repeat;
}

.error-message {
	color: red;
	line-height: 22px;
	float: left;
	text-align: left;
	padding-left: 5px;
}
.form .error-message{
	display: block;
	float: none;
	margin-bottom: 5px;
	padding: 0;
	font-size: 11px;
}

.float_left {
	float: left;
}

.float_right {
	float: right;
}

a.act,
span.act {
	padding: 1px 6px;
	background-image: url(img/icon.png)
}

a.act:hover {
	text-decoration: none;
}

span.doc,span.docx{
	background-position: -37px -144px;
}
span.xls,span.xlsx{
	background-position: -66px -144px;
}
span.ppt,span.pptx{
	background-position: -123px -144px;
}
span.pdf{
	background-position: -95px -144px;
}
span.txt{
	background-position: -153px -144px;
}
span.zip,span.rar{
	background-position: -7px -144px;
}
span.link{
	background-position: -182px -144px;
}
a.download{
	background-position: -213px -144px;
	padding: 1px 7px;
}
a.download:hover{
	background-position: -244px -143px;
}

a.del {
	background-position: -8px -97px;
}

a.del:hover {
	background-position: -38px -97px;
}

/********************** #header *************************/
/***** #header *****/

#header input.small,#header input.medium,#header input.larger {
	padding: 2px;
}

#header input.small {
	width: 80px;
}

#header input.medium {
	width: 160px;
}

#header input.larger {
	width: 250px;
}

#header select {
	padding: 1px 2px;
}

#header select.small {
	width: 60px;
}
#header select.medium {
	width: 160px;
}

#header select.larger {
	width: 250px;
}

#header input.submit {
	background-image: url(img/button.png);
	background-position: -101px -1px;
	border: none;
	text-indent: -1000px;
	width: 34px;
	height: 22px;
}

#header input.submit:hover {
	cursor: pointer;
	background-position: -101px -23px;
}

#header .top .social {
	float: left;
	margin-left: 10px;
}

#header .top > ul >li >ul {
		display: none;
}

#header .top ul li {
	display: inline;
		background-color: #05274d;
		float: left;
	padding-bottom: 5px;
}
#header .top ul li a{
	font-size: 12px;
	color: #696969;
	border-right: 1px solid #fff;
}
#header .top ul li:last-child a{
	border-right: none;
}
#header .top ul li span {
		color: #fff;
		font-family: tradegothiclt18;
		font-size: 15px;
		padding: 0 10px;
}
#header .top ul li:last-child span{
	border:none;
}

/***** .search *****/
#header .search {
	float: right;
	margin-right: 10px;
	height: 22px;
	display:none;
}
#header .search input.medium{
	padding: 3px 5px 1px;
}

#header .search ul li {
	display: inline;
}

#header input.medium {
	width: 160px;
}

/***** .cart ****/
#header .cart{
	float: right;
	margin-right: 10px;
	display:none;
}
#header .cart a{
	line-height: 20px;
}

/***** .currency *****/
#header .currency {
	float: right;
	margin-right: 5px;
	height: 22px;
}

/***** #navmenu *****/
#bg_nav,#bg_nav .left,#bg_nav .right{
	height: 40px;
}

#bg_nav {
	position: relative;
	z-index: 100;
	float: left;
	width: 100%;
}

#bg_nav .right,#bg_nav .left {
	width: 7px;
	position: absolute;
	top: 0;
	z-index: 2;
}

#bg_nav .right {
	position: absolute;
	right: 0;

}

#bg_nav .left {

	left: 0;
}
.nav ul{
	z-index:100;
	margin: 0px;
	transition: 0.5s;
	padding: 0;
	list-style-type: none;
}
div#menu-nav.navbar-fixed-top{
	background: url(img/h_bg_header.png) repeat-x 0 0;
	box-shadow: 0 2px 5px #c10d0e;
	height: 55px;
}
div#menu-nav.navbar-fixed-top .nav ul{
	float: right;
	transition: 0.5s;
}
div#menu-nav.navbar-fixed-top .nav ul li span{
	height: 55px;
	line-height: 55px;
}
div#menu-nav.navbar-fixed-top  .nav > ul > li > ul{
	top: 55px!important;
}
.logo_top{
	display: none;
}
.navbar-fixed-top .container{
	position: relative;
}
.navbar-fixed-top .container .logo_top{
	left: 25px;
	display: block;
		position: absolute;
		top: 6px;
	z-index: 1200;
}
.navbar-fixed-top .container .logo_top:hover img,.logo:hover img{
	opacity: 1!important;
}
/*Top level list items*/
.nav ul li{
	position: relative;
	display: inline;
	float: left;
	padding:0px;
	margin-right: 1px;
		transition: 0.5s;
}

/*Top level menu link items style*/
.nav ul li a{
	display: block;
	color: white;
/* 	color: #2d2b2b; */
	text-decoration: none;
	padding-right:5px;
}

* html .nav ul li a,
* html .nav ul li a span{ /*IE6 hack to get sub menu links to behave correctly*/
	display: inline-block;
}

.nav ul li a:link, #header .nav ul li a:visited{
	color: white;
}

/* hover cap 1 */
.nav ul li.selected,
.nav ul li:hover,
.nav ul li.iehover,
.nav ul li.current{ /*CSS class that's dynamically added to the currently active menu items' LI A element*/
	transition: 0.5s;
}
.nav ul li.selected a,
.nav ul li a:hover,
.nav ul li.current a{
	cursor:pointer;
	color: white;
	transition: 0.5s;
}

.nav ul li.selected a span,
.nav ul li a span:hover,
 .nav ul li a:hover span,
.nav ul li.current a span{
/* 	background:#c10d0e; */
	color: white;
/*     transition: 0.5s; */
}
/* end hover cap 1 */


/*1st sub level menu*/
.nav > ul> li> ul{
	position: absolute;
	left: 0;
	display: none; /*collapse all sub menus to begin with*/
	visibility: hidden;
	margin: 0;
	width: 222px!important;
		overflow: inherit!important;
}

/*Sub level menu list items (undo style from Top level List Items)*/
.nav ul li ul li{
	display: list-item;
	float: none;
	padding:0;
	margin: 0;
}

/*All subsequent sub menu levels vertical offset after 1st level sub menu */
.nav ul li ul li ul{
	top: 0;
	position: absolute;
}

/* Sub level menu links style */
.nav ul li ul li a,
.nav ul li.selected ul li a{
	margin: 0;
	padding:0 7px;
	height:28px;
	line-height:28px;
	border-bottom:1px solid #FFF4E5;
}

/* hover cap 2 tro di */
 .nav ul li ul li a.selected,
 .nav ul li ul li a:hover{
	background:#05274d;
	color:#fff;
}
div#menu-nav {
	height: 40px;
	margin: 0 auto;
	background-color: #06284e;
	border-bottom: 4px solid #bd0e0b;
}
/* Holly Hack for IE \*/
* html .nav{height: 1%;} /*Holly Hack for IE7 and below*/


/* ######### CSS classes applied to down and right arrow images  ######### */

.downarrowclass{
	position: absolute;
	top: 12px;
	right: 7px;
}

.rightarrowclass{
	position: absolute;
	top: 8px;
	right: 5px;
}

/* ######### CSS for shadow added to sub menus  ######### */

.ddshadow{ /*shadow for NON CSS3 capable browsers*/
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	background: silver;
}

.toplevelshadow{ /*shadow opacity for NON CSS3 capable browsers. Doesn't work in IE*/
	opacity: 0.8;
}

/***** #sidebar_left *****/
#sidebar_left {
	float: left;
	width: 250px;
}
.sidebar .adv ul {
		margin: 0 auto;
		width: 250px;
}
.sidebar .adv .top {
		background: none!important;
}
.list_post #content,
.filter_post #content .des{
	background:white;
}
.filter_post #content .des{
	padding:0px 10px!important;
}
#content .top,#content .bottom {
	position: absolute;
	height: 5px;
	width: 100%;
	background: transparent;
}

#content .top {
	top: -5px;
}

#content .bottom {
	bottom: -5px;
}

#main.no_col_left #content {
	margin-left: 0;
}

#main.no_col_right #content{
	margin-right: 0;
}
/**
 * .order_book
 */
 #main.no_col_right #content{
	padding:0px 0px 10px 0;
}
#content .thumb a{
	line-height: 1px;
	display: block;
}

#content select {
	padding: 4px 2px;
}

#content input.small {
	width: 90px;
}
#content .box_act input {
	padding: 0;
	height: auto;
}
#content input.medium {
	width: 200px;
}

#content input.larger {
	width: 300px;
		height: 30px;
}
#content input.larger1 {
	width: 246px;
}

#content select.small {
	width: 96px;
}
#content select.small1 {
	width: 70px;
}

#content select.medium {
	width: 206px;
}

#content select.larger {
	width: 313px;
		height: 27px;
}

#content textarea.small {
	width: 200px;
	height: 90px;
}

#content textarea.medium {
	width: 300px;
	height: 90px;
	padding: 5px;
}

#content textarea.larger {
	width: 400px;
	height: 90px;
}

#content span.im {
	color: #ff0000;
	margin-left: 5px;
}

#content label.error {
	color: #ff0000;
	display: block;
	font-size: 11px;
	font-style: italic;
}

/*********************** #sidebar **************************/
.sidebar {

}

.sidebar .sidebar_middle {

}

.sidebar .sidebar_bottom {

}

.sidebar .thumb a{
	display: block;
	line-height: 1px;
}
.sidebar input {
	padding: 2px 4px;
}

.sidebar select {
	padding: 2px 1px;
}

.sidebar input.small {
	width: 50px;
}

.sidebar input.medium {
	width: 100px;
}

.sidebar input.larger {
	width: 170px;
}

.sidebar select.small {
	width: 60px;
}

.sidebar select.medium {
	width: 110px;
}

.sidebar select.larger {
	width: 180px;
}

/***** .box *****/
.sidebar .box {
	background: #fff;
	position: relative;
	margin-bottom: 8px;
	padding-bottom: 10px;
}

.sidebar .box .top,.sidebar .box .bottom {
	position: absolute;
	height: 10px;
	width: 100%;
	z-index: 10;
	background: transparent;
}
.sidebar .box span.title {
	background: url("img/s_box_title.png") repeat-x top left;
	display: block;
	text-align: center;
	height: 37px;
		line-height: 37px;
		text-transform: uppercase;
	color: #fff;
		font-family: tradegothicltbold20;
	margin-bottom: 10px;
	font-weight: bold;
	font-size:20px;
}

/***** .maker .price *****/
.sidebar .maker ul li,.sidebar .box.price ul li {
	position: relative;
	line-height: 18px;
	padding:5px 0px;
}

.sidebar .maker ul li a {
	display: block;
}

.sidebar .box.maker ul a {
	background: url(img/arrow7.png) left 8px no-repeat;
	padding-left: 12px;
}
.sidebar .box.maker ul a:hover{
	color: #ff0000;
}

.sidebar .box.price ul a {
	background: url(img/arrow1.png) left 5px no-repeat;
	padding-left: 6px;
}
.sidebar .maker ul li.current a{
	font-weight: bold;
}
.sidebar .maker ul li img {
	position: absolute;
	top: 0;
	right: 0;
}

/***** .product_run *****/
.sidebar .product_run .caroufredsel_wrapper {
	width: 180px;
	margin: 0 auto !important;
	text-align: center;
	min-height: 150px !important;
}

.sidebar .product_run .thumb {
	position: relative;
	text-align: center;
	width: 160px;
	margin: 0 auto;
	display:none;
}

.sidebar .product_run .thumb span {
	display: block;
	width: 30px;
	height: 30px;
	background-image: url(img/icon.png);
	line-height: 30px;
	color: #fff;
	font-size: 11px;
	font-weight: bold;
	text-align: center;
	text-decoration: blink;
}

.sidebar .product_run .thumb span.discount {
	position: absolute;
	top: 0;
	left: 0;
	background-position: 0 0;
}

.sidebar .product_run .thumb span.promotion {
	position: absolute;
	top: 0;
	right: 0;
	background-position: -30px 0;
}

.sidebar .product_run p {
	font-size: 15px;
		text-align: left;
}
.sidebar .product_run p.name{
	padding-bottom:10px;
}
.sidebar .product_run p.name a{
	color:#F3672B;
	font-weight:bold;
}

.sidebar .product_run p.price {
	color: #696969;
}

.sidebar .product_run p.price .old {
	text-decoration: line-through;
	color: #888;
}

.sidebar .product_run p.cart a {
	display: block;
	width: 89px;
	padding-right: 15px;
	line-height: 25px;
	background-image: url(img/button.png);
	background-position: 0px -66px;
	color: #fff;
	margin: 0 auto;
}

.sidebar .product_run p.cart a:hover {
	cursor: pointer;
	text-decoration: none;
	background-position: 0px -91px;
}

.sidebar .product_run ul li {
	padding-bottom: 10px;
	position: relative;
	border-bottom:1px solid #cecece;
	margin-bottom:10px;
}

.sidebar .product_run p.cart {
	position: absolute;
	bottom: 5px;
	left: 0;
	width: 100%;
	display: none;
}

.sidebar .product_run ul li:hover p.cart {
	display: block;
}

/***** .newsletter *****/
.sidebar .newsletter p {
	text-align: center;
}

.sidebar .newsletter button.submit {
	width: 90px;
	margin-top: 10px;
	background-image: url(img/button.png);
	background-position: -101px -132px;
	border: none;
	width: 97px;
	height: 24px;
	color: #fff;
}
.sidebar .newsletter #newsletter_result{
	display: none;
	color: #ff0000;
	font-style: italic;
	margin-top: 3px;
}

.sidebar .adv {
		padding-bottom: 0!important;
		margin-bottom: 10px;
}
.sidebar .newsletter button.submit:hover {
	cursor: pointer;
	background-position: -101px -156px;
}

/***** .adv ****/
.sidebar .adv ul li{
	text-align: center;
		margin-bottom: 5px;
}


/***** .search *****/
.sidebar .search input {
	margin-bottom: 10px;
}

.sidebar .search select {
	margin-bottom: 10px;
}
.sidebar .search .submit{
	text-align: center;
}
.sidebar .search .submit input{
	margin-bottom: 0;
	background: url(img/c_button_cart.png) no-repeat 0 0;
	border: none;
	width: 105px;
	height: 28px;
	color: #fff;
		font-weight: bold;
}

.sidebar .search .submit input:hover {
	background-position: 0 -28px;
	cursor: pointer;
}

.sidebar .search p {
	text-align: center;
}

.sidebar .search li {
	text-align: center;
}

.sidebar #search_post {
	display: none;
}


/***** .support *****/
.box_footer .support li{
	padding-bottom: 8px;
	text-align: center;
}
.box_footer  .support p{
	text-align: left;
	line-height: 22px;
	padding-bottom: 2px;
}
.box_footer  .support p.name span{
		font-size: 17px;
		font-style: italic;
		font-weight: bold;
	color: #05274d;
}
.box_footer .support {
		position: absolute;
		right: 0;
		top: 20px;
}
.box_footer .support ul{
		list-style: none;
		margin: 0;
		padding: 0;
}
/***** .video *****/
.sidebar .video li{
	text-align: center;
}
.sidebar .video p.more{
	text-align: right;
	padding-right: 15px;
}


/***** .gallery *****/
.sidebar .gallery ul,
.sidebar .gallery .caroufredsel_wrapper{
	margin: 0 auto !important;
}
.sidebar .gallery li{
	text-align: center;
}


/***** .poll *****/
.sidebar .poll li{
	font-style: italic;
	font-weight: bold;
	line-height: 15px;
}
.sidebar .poll li ul li{
	font-weight: normal;
	font-style: normal;
}
.sidebar .poll li ul{
	margin-bottom: 10px;
}
.sidebar .poll .submit{
	text-align: center;
}
.sidebar .poll .submit input{
	background: url("img/button.png") -101px -132px no-repeat;
	height: 24px;
	width: 97px;
	border: none;
	color: #fff;
}
.sidebar .poll .submit input:hover{
	background-position: -101px -156px;
	cursor: pointer;
}
.sidebar .poll a.poll_result{
	background: url("img/button.png") -101px -132px no-repeat;
	padding: 5px 12px 4px;
	color: #fff;
}
.sidebar .poll a.poll_result:hover{
	background-position: -101px -156px;
	text-decoration: none;
}

#poll_result{
	position: fixed;
	top: 100px;
	left: 0;
	width: 100%;
	z-index: 10000;
	display: none;
}
#poll_result div{
	width: 500px;
	margin: 0 auto;
	background: #fff;
	border: 1px solid #aaa;
	border-radius: 5px;
	padding: 20px 10px;
	box-shadow: 0 0 7px #999;
}
#poll_result a.close{
	padding: 5px 12px;
	float: right;
	margin-right: -7px;
	margin-top: -17px;
	background: url(img/icon.png) -92px 0 no-repeat;
}
#poll_result a.close:hover{
	background-position: -122px 0;
	text-decoration: none;
}
#poll_result ul{
	width: 100%;
}
#poll_result ul li{
	margin-bottom: 20px;
}
#poll_result ul li ul{
	margin-top: 10px;
}
#poll_result ul li ul li{
	margin: 0;
}
#poll_result ul li div{
	padding: 0;
	box-shadow: none;
	border-radius: 3px;
	line-height: 18px;
	margin-bottom: 5px;
	border: 1px solid #0087E8;
}
#poll_result ul li div p{
	background: #0094FF;
	text-align: center;
	font-size: 12px;
	color: #fff;
}


/***** .post *****/
.sidebar .post .caroufredsel_wrapper{
	margin: 0 auto !important;
}
.sidebar .post ul li a {
	background: url(img/arrow2.png) left 6px no-repeat;
	padding-left: 8px;
}
#photos ul {
		float: left;
		list-style: none outside none;
		padding: 0;
}
#photos ul li{
		float: left;
		margin: 10px 0 0 10px;
}
#photos ul li img{
		height: 229px;
		width: 329px;
		float: left;
}
/***** .document *****/
.sidebar .document ul li a {
	margin-left: 5px;
}

/***** .tag *****/
.sidebar .tag p {
	padding: 0 10px;
	text-align: center;
}

.sidebar .tag a {
	padding-right: 3px;
	line-height: 24px;
}

.sidebar .tag a.size1 {
	font-size: 15px;
}

.sidebar .tag a.size2 {
	font-size: 17px;
}

.sidebar .tag a.size3 {
	font-size: 19px;
}

.sidebar .tag a.size4 {
	font-size: 21px;
}

/***** .counter *****/
.sidebar .counter table {
	width: 220px;
	margin: 0 auto;
	font-family: Tahoma;
}

.sidebar .counter table th {
	text-align: left;
	font-weight: normal;
	padding-left: 20px;
}

.sidebar .counter table td {
	text-align: right;
}

.sidebar .counter tr.online th {
	background: url(img/s_counter1.png) left 2px no-repeat;
}

.sidebar .counter tr.yesterday th {
	background: url(img/s_counter2.png) left 2px no-repeat;
}

.sidebar .counter tr.today th {
	background: url(img/s_counter3.png) left 2px no-repeat;
}

.sidebar .counter tr.week th {
	background: url(img/s_counter4.png) left 2px no-repeat;
}

.sidebar .counter tr.month th {
	background: url(img/s_counter5.png) left 2px no-repeat;
}

.sidebar .counter tr.year th {
	background: url(img/s_counter1.png) left 2px no-repeat;
}

.sidebar .counter tr.total th {
	background: url(img/s_counter_total.png) left 2px no-repeat;
}
.sidebar .counter tr.total_top th{
	text-align: center;
	line-height: 30px;
	padding: 0 0 5px 0;
}
.sidebar .counter #counter{
	text-align: center;
}
.sidebar .counter tr.total_top p {
	font-weight: bold;
	text-align: center;
	background: #696969;
	color: #fff;
	font-size: 11px;
	margin: 0 auto 5px;
	font-size: 15px;
	border-radius: 3px;
	display: inline;
	padding: 2px 4px;
}
.sidebar .counter tr.total_top p span{
	padding: 0 4px;
	border-right: 1px solid #666;
}
.sidebar .counter tr.total_top p span:last-of-type{
	border: none;
}

/******.why_us*******/
.sidebar .why_us .wbu{
	padding:0px 10px;
}
.sidebar .why_us .wbu p,.sidebar .why_us .wbu h3 {
		color: #787878;
		padding-left: 20px;
}
.sidebar .why_us .wbu p{
	padding-bottom:10px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 10px;
}
.sidebar .why_us .wbu h3 {
		background: url("img/tickw.png") no-repeat;
		margin-bottom: 6px;
}

/*********************** #content ***********************/
/***** .box_info_page *****/
#content .box_info_page {
	position: relative;
}

#content .box_info_page .top,#content .box_info_page .bottom {
	position: absolute;
	width: 100%;
	height: 5px;
	background: transparent;
}

#content .box_info_page .top {
	top: -5px;
}

#content .box_info_page .bottom {
	bottom: -5px;
}

#content .box_info_page .des {
	text-align: left;
}

#content .box_info_page .title {
	line-height: 30px;
}

#content .box_info_page {
			border: 1px solid #cdcdcd;
	margin-bottom: 10px;
	padding:10px;
	background: #dfdfdf;
}

#content a.add{
	background-position: -62px -97px;
}
#content a.add:hover{
	background-position: -82px -97px;
}
#content a.sub{
	background-position: -100px -97px;
}
#content a.sub:hover{
	background-position: -120px -97px;
}

/**** .tag *****/
#content div.tag{
	padding: 7px 0 8px;
}
#content div.tag span{
	background: url(img/tag.png) left center no-repeat;
	font-weight: bold;
	display: block;
	float: left;
	width: 82px;
	height: 22px;
	line-height: 26px;
	text-align: right;
	margin-right: 5px;
	text-transform: uppercase;
	color: #003BFF;
}
#content div.tag a{
	color: #003BFF
}

/**** .rate ****/

#content div.rate {
		display: inline-block;
		width: 100%;
}
#content div.rate span{
	float: left;
	line-height: 25px;
	padding-right: 10px;
	font-weight: bold;
}

/***** .box_content *****/
#content .box_content {
	position: relative;
	margin-bottom: 15px;
}
.member #content .box_content{
	padding:0px 10px 10px 10px;
	background:white;
}

#content .box_content .top,#content .box_content .bottom {
	position: absolute;
	width: 100%;
	height: 5px;
	background: transparent;
}

#content .box_content .top {
	top: -5px;
}

#content .box_content .bottom {
	bottom: -5px;
}

.list_document #content .box_content .des,
.detail_document #content .box_content .des{
	background:white;
}

#content .box_content .title {
/* 	background: url(img/c_box_content_title_l.png) left top no-repeat; */
/* 	padding-left: 4px; */
	line-height:22px;
	margin-bottom:10px;
}
.detail_product #content .box_content .title{
	margin:0!important;
}

#content .box_content .title_right {
/* 	background: url(img/c_box_content_title_r.png) right top no-repeat; */
/* 	padding-right: 4px; */
}
.detail_product #content .box_content .title_right{
	padding:0px 10px;
	background:white;
}
#content .box_content .title_center {
/* 	background: url(img/c_box_content_title_c.png) center top repeat-x; */
	padding: 0px;
	height: 41px;
	line-height: 41px;
	position: relative;
/* 	background:#2980b9; */
	background:white;
}
/* .home #content .box_content .title_center{ */
/* 	background:#2980b9; */
/* } */
#content .box_content.read .title,
#content .box_content.read .title_right,
#content .box_content.read .title_center{
	background: none;
	padding: 0;
	height: auto;
}
#content .box_content.read .title span{
	padding:0!important;
	border-bottom:1px solid #2980b9;
}

#content .box_content .title a.more {
	background-image: url(img/arrow3.png);
	background-position: 90% 17px;
	background-repeat: no-repeat;
	padding-right: 15px;
	font-size: 11px;
	color:#696969;
}

#content .box_content a.more:hover {
	background-position: 100% 17px;
}
#content .box_content .title span {
	font-size: 16px;
	color:white;
}
#content .box_content .title span{
	display: block;
	font-size: 16px;
	line-height: 41px;
}
#content .box_content .title a {
	float: right;
}
#content .box_content .title .title_right .title_center span {
		color: #2980b9;
	background:white;
	padding:0px 10px;
}
.detail_product #content .box_content .title .title_right .title_center{
	height:60px;
	border-bottom:1px solid #cecece;
}
.detail_product #content .box_content .title .title_right .title_center span{
	padding:0;
	font-size:16px;
}
#content .box_content .title nav{
	position: absolute;
	top: 0;
	right: 5px;
}
#content .box_content .title .router{
	position:absolute;
	bottom:-9px;
	left:0px;
	line-height:25px;
}
#content .box_content .title ul.sort {
	position: absolute;
	top: 0px;
	right: 5px;
	margin: 0;
	padding: 0;
	list-style: none;
}

#content .box_content .title ul.sort li {
	display: inline;
	float: left;
	color:#696969;
}

#content .box_content .title ul.sort li a {
	padding: 0 5px;
	color:#333;
}

#content .box_content .title ul.sort li a.desc {
	background: url(img/c_sort_desc.png) 98% center no-repeat;
	padding-right: 10px;
}

#content .box_content .title ul.sort li a.asc {
	background: url(img/c_sort_asc.png) 98% center no-repeat;
	padding-right: 10px;
}

#content .box_content p.date-time{
	font-size: 12px;
	color: #555;
	font-style: italic;
}
/****** .detail_product ******/

.detail_product #content .info {
	float: left;
	text-align: justify;
}

.detail_product #content .info span.title {
	background: transparent;
	font-size: 20px;
	padding: 0 0 0 2px;
}
.detail_product #content .info hr{
	border: none;
	border-bottom: 2px solid #ddd;
	margin: 10px 0;
}

.detail_product #content .info table th {
	width: 105px;
}

.detail_product #content .info table th,.detail_product #content .info table td{
	border-bottom: 1px dotted #f9f9f9;
}

.detail_product #content .info table tr.price span.old {
	text-decoration: line-through;
	color: #999;
}

.detail_product #content .info table tr.price span.new {
	color: #ff0000;
	font-weight: bold;
	font-size: 14px;
}
.detail_product #content .info span.save{
	font-style: italic;
	color: green;
}

.detail_product #content .info table tr.tag a {
	color: blue;
}

.detail_product #content .info table tr.promotion td {
	font-style: italic;
	color: #ff0000;
}
.detail_product #content .info table tr.status td{
	color: green;
}
.info > h3 {
		font-size: 18px;
		margin-bottom: 10px;
}
.detail_product #content .info a.cart {
		background: url("img/c_button_cart.png") no-repeat scroll 0 0;
		color: #fff;
		display: block;
		font-size: 12px;
		font-weight: bold;
		height: 28px;
		line-height: 28px;
		margin: 10px 0;
		text-align: center;
		width: 106px;
		text-transform: uppercase;
}
.detail_product #content .info a.cart:hover {
	text-decoration: none;
	background-position: 0 -28px;
}

.detail_product #content .support span.title {
	font-weight: bold;
	font-size: 14px;
	padding: 0;
}

.detail_product #content .support {
	background: #f9f9f9;
	margin: 0 0 10px 0;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.detail_product #content .support ul {
	margin: 3px 0 0 0;
	padding: 0;
	overflow: hidden;
}

.detail_product #content .support ul li {
		background: url("img/phone.png") no-repeat scroll left center;
		display: block;
		float: left;
	margin-bottom: 5px;
		list-style: none outside none;
		padding-left: 45px;
		height: 40px;
		width: 224px;
}
.detail_product #content .support ul li span {
		font-size: 17px;
		font-weight: bold;
		color: #2a76d4;
}
.detail_product #content .support ul p {
	float: left;
}

/*** .detail_product #content .product_des ****/
.detail_product #content .product_des {
	margin-top: 10px;
	overflow: hidden;
}

.detail_product #content .product_des .tab_container {
	padding: 10px;
	background:white;
}

.detail_product #content .tab_content .box_product {
	width: 238px;
	font-size: 13px;
}
.detail_product #content .product_des {
	font-size: 14px;
	line-height: 24px;
	text-align: justify;
}
.detail_product #content .product_des p,
#content .read .des p{
	margin-bottom: 10px;
}

.detail_product #content .gallery:hover a.previous,.detail_product #content .gallery:hover a.next
	{
	display: block;
}

.detail_product #content .gallery a.previous,.detail_product #content .gallery a.next{
	display: none;
	width: 25px;
	height: 50px;
	background-image: url(img/icon.png);
	position: absolute;
	top: 65px;
	z-index: 100;
}

.detail_product #content .gallery a:hover {
	text-decoration: none;
}

.detail_product #content .gallery a.previous {
	left: 4px;
	background-position: 0 -34px;
}

.detail_product #content .gallery a.next {
	right: 4px;
	background-position: -35px -34px;
}

.detail_product #content .gallery a.previous:hover {
	background-position: -61px -34px;
}

.detail_product #content .gallery a.next:hover {
	background-position: -96px -34px;
}
/*** .detail_product #content .gallery  ****/
.detail_product #content .info_top{
	background:white;
	padding:10px;
}
.detail_product #content .gallery {
	float: left;
/* 	margin: 10px; */
	position: relative;
}


/* Etalage container (large thumb + small thumbs): */
#thumb_img {
	display: none;
}

/* Large thumbnail: */
#thumb_img .etalage_thumb {
	background: white url(img/loading.gif) center no-repeat;
/* 	border: 2px solid #ddd; */
	padding: 3px;
}

/* Large thumbnail - image (in case you want to add a border around the image within the frame) */
#thumb_img .etalage_thumb_image {

}

/* Small thumbnails: */
#thumb_img .etalage_small_thumbs li {
	background: white;
	border: 1px solid #ddd;
	margin: 10px;
	padding: 3px;
		width: 63px;
}

/* The one on the left that makes them slide */
#thumb_img ul li.etalage_smallthumb_first {

}

/* The one on the right that makes them slide */
#thumb_img ul li.etalage_smallthumb_last {

}

/* The currently active one */
#thumb_img ul li.etalage_smallthumb_active {
	box-shadow: 0 0 10px #ddd;
}

/* Zoomed image area: */
#thumb_img .etalage_zoom_area,.etalage_zoom_area {
	background: white url(img/loading.gif) center no-repeat;
	border: 1px solid #ddd;
	padding: 6px;
	box-shadow: 0 0 10px #ddd;
}

/* Magnifier area (thumbnail hovering rectangle): */
#thumb_img .etalage_magnifier {
	background: white;
	border: 1px solid #bbb;
}

/* Icon that will apear at the left bottom of the large thumbnail (optional): */
#thumb_img .etalage_icon {
	background: url(img/zoom.png) no-repeat;
	width: 14px;
	height: 14px;
	display: none !important;
}

/* Hint that will apear at the top right of the large thumbnail (optional): */
#thumb_img .etalage_hint { /*	background: url(img/hint.gif) no-repeat;*/
	width: 130px;
	height: 57px;
}

/* Description area (optional) */
#thumb_img .etalage_description {
	background: #333;
	font-style: italic;
	margin: 10px;
	padding: 6px 10px;
	color: #fff;
	border-radius: 20px;
	box-shadow: 0 0 3px #ddd;
}
.detail_hotel .des .info_top .info .support {
		width: 100% !important;
}

/*
 * ------------------------------------ FUNCTIONALITY --------------------------------------
 * The following CSS serves to make Etalage function properly. Don't edit or edit carefully.
 * -----------------------------------------------------------------------------------------
 */
.etalage,.etalage_thumb,.etalage_thumb_image,.etalage_source_image,.etalage_zoom_preview,.etalage_icon,.etalage_hint
	{
	display: none
}

.etalage,.etalage ul,.etalage li,.etalage img,.etalage_hint,.etalage_icon,.etalage_description
	{
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none
}

.etalage,.etalage_magnifier div,.etalage_magnifier div img,.etalage_small_thumbs ul,ul .etalage_small_thumbs li,.etalage_zoom_area div,.etalage_zoom_img
	{
	position: relative
}

.etalage,.etalage_small_thumbs li {
	float: left
}

.etalage_right {
	float: right
}

.etalage li {
	position: absolute;
	z-index: 100;
}

.etalage img {
	vertical-align: bottom;
	max-width: none
}

.etalage_magnifier {
	cursor: default
}

.etalage_magnifier div,.etalage_small_thumbs {
	overflow: hidden
}

.etalage_magnifier div img {
	display: none
}

.etalage_icon,.etalage_hint {
	cursor: default;
	width: 0;
	height: 0;
	overflow: hidden
}

.etalage_small_thumbs li.vertical {
	float: none
}

.etalage_zoom_area div {
	overflow: hidden;
	z-index: 997
}

.etalage_zoom_preview {
	position: absolute;
	z-index: 998
}

.etalage_zoom_img,.etalage_hint {
	z-index: 999
}

.etalage {
	direction: ltr
}

div.etalage_description {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 999
}

div.etalage_description.rtl {
	direction: rtl;
	text-align: right
}
.ribbon p {
	white-space: nowrap;
}
/*** zoom popup ***/
.detail_product #content .gallery .zoom{
	position: absolute;
	bottom: 56px;
	right: 5px;
	z-index: 100;
	background: url(img/icon.png) -3px -171px no-repeat;
	display: block;
	width: 35px;
	height: 33px;
}
.detail_product #content .gallery .zoom:hover{
	background-position: -39px -171px;
}


/**** detail_hotel ****/
#content .detail_hotel .related span.title{
	margin: 30px 0 20px;
}
#content .detail_hotel .gallery .zoom{
	top: 215px;
}
#content .detail_hotel .box_hotel{
	float: left;
	width: 100%;
	height: 140px;
	overflow: hidden;
	margin-bottom: 20px;
	text-align: center;
	background:white;
}
#content .detail_hotel .box_hotel.last{
	margin-right: 0;
}
#content .detail_hotel .box_hotel .address{
	color: #555;
	font-weight:bold;
}
#content .detail_hotel .box_hotel .info{
	padding: 0;
	overflow: hidden;
	text-align: left;
}
#content .detail_hotel .box_hotel .thumb{
	text-align: center;
	margin-right: 5px;
	float:left;
}
#content .detail_hotel .box_hotel p.name{
	text-align: left;
	text-transform: uppercase;
	height: 22px;
	line-height: 22px;
	overflow: hidden;
}
#content .detail_hotel .box_hotel p.name a{
	color: #2A76D4;
	font-weight: bold;
}

.box_product .description .name {
/*     border-bottom: 1px dashed #555; */
		margin-bottom: 5px;
		padding-bottom: 5px;
}
.top_view > header {
		float: left;
		width: 100%;
}
.top_view h1{
		float: left;
}
.star {
		background: url("img/5star.png") no-repeat scroll left top;
		display: inline-block;
		height: 15px;
		margin-left: 5px;
}
/******** #comment ********/
#comment{
	margin-top: 15px;
}
#comment .title2{
	position: relative;
	height: 32px;
	border-bottom: 2px solid #ddd;
}
#comment .title2 span{
	font-weight: bold;
	font-size: 18px;
	line-height: 28px;
	color: #ff0000;
}
#comment .title2 a.write{
	position: absolute;
	top: 0;
	right: 0;
	padding: 4px 0;
	background: url(img/button.png) -202px -132px no-repeat;
	width: 121px;
	text-align: center;
	color: #333;
}
#comment .title2 a.write:hover{
	background-position: -202px -158px;
	text-decoration: none;
}
#comment ul.comment{
	margin: 0 0 30px 0;
	padding: 0;
	list-style: none;
}
#comment ul.comment li{
	border-bottom: 1px solid #ddd;
	padding: 10px 0;
	text-align: justify;
}
#comment ul.comment li p.title{
	background: transparent;
	padding: 0;
	font-weight: bold;
	height: 25px;
}
#comment ul.comment li p.name{
	color: #333;
	font-weight: bold;
}
#comment ul.comment li p.name span{
	font-size: 11px;
	color: #444;
}
#comment ul.comment ul{
	background: #F4F4F4;
	padding-left: 25px;
	margin-top: 8px;
	padding-right: 10px;
	margin-bottom: 6px;
}
#comment ul.comment ul li:last-of-type{
	border-bottom: none;
}
#comment span.title3{
	font-size: 18px;
	line-height: 25px;
	font-weight: bold;
	display: block;
	border-bottom: 2px solid #ddd;
	color: #FF0000;
	margin-top: 20px;
}
#comment table{
	margin-top: 20px;
}
#comment table th{
	text-align: left;
	width: 110px;
}
#comment table th,
#comment table td{
	padding-bottom: 8px;
}
#comment table .submit button{
	background: #eee url(img/button.png) -202px -66px no-repeat;
	border: none;
	height: 28px;
	width: 111px;
	color: #fff;
}
#comment table .submit button:hover{
	background-position: -202px -94px;
	cursor: pointer;
}
#comment p.button{
	text-align: right;
}
#comment p.button a.answer{
	font-size: 12px;
	border-radius: 3px 0 3px 0;
	margin-right: 10px;
	color: #E20808;
}
#comment p.button a.like{
	font-size: 12px;
	margin-right: 10px;
	font-weight: bold;
	color: #333;
}
#comment p.button a.like.no_like{
	font-weight: normal;
}
#comment ul li li p.button a.like{
	margin-right: 0;
}
#comment p.button a.like span{
	margin: 0 3px;
}
#comment p.button a.like span.like{
	background: url(img/icon.png) -164px -5px no-repeat;
	padding: 2px 7px 0;
}
#comment p.button a.like:hover{
	text-decoration: none;
}


#content .box_content .box_product .top {
	top: -5px;
}

#content .box_content .box_product .bottom {
	bottom: -5px;
}
/**** .option_related ***/
#content .box_content .box_product.option_related{
	border: none;
}

/***** .des_scroll ******/
#content .box_product .des_scroll{
	position: absolute;
/*	height: 198px;*/
/*	width: 259px;*/
	text-align: justify;
	display: none;
	overflow: hidden;
	top: 0;
	left: 0;
}
#content .box_product .des_scroll .tb,
#content .box_product .des_scroll .bt,
#content .box_product .des_scroll .lr,
#content .box_product .des_scroll .rl{
	position: absolute;
	background: #f9f9f9;
	padding: 5px;
}

#content .box_product .des_scroll .lr,
#content .box_product .des_scroll .rl{
	top: 0;
}

#content .box_product .des_scroll .tb,
#content .box_product .des_scroll .bt{
	left: 0;
}

#content .box_product:hover .des_scroll{
	display: block;
}
#content .box_product .des_scroll p{
	text-align: justify;
}

/*************box_testimonial*****************/
#content .box_testimonial .box-tes{
	margin-bottom:15px;
	padding:10px;
	background:white;
}
#content .box_testimonial .box-tes .thumb{
	float: left;
	margin-right: 8px;
}
#content .box_testimonial .box-tes .info{
	text-align: justify;
}
#content .box_testimonial .box-tes p.author{
	text-align: right;
	font-weight: bold;
}

#content .box_content #testimonial{
	overflow: hidden;
}

#content .box_content .box_testimonial{
	margin-bottom: 8px;
}
#content .box_content .box_testimonial .title{
/* 	background: #E5E5E5; */
	padding: 4px 0 6px;
	font-weight: bold;
	font-size: 13px;
	color: #333;
	height:18px;
}
#content .box_content .box_testimonial .title a{
	float:left;
}
#content .box_content .box_testimonial p.description{
	padding-bottom: 10px;
}
#content .box_content .box_testimonial p.about{
	text-align: right;
	text-decoration: underline;
	font-size: 0.8em;
}
.contact_form_right label {
		float: left;
		text-align: left;
}

#content .contact_form_right input.larger1 {
		width: 226px;
}
#content .contact_form_right .row{
	margin-bottom: 5px;
}
#content .contact_form_right .row .medium{
		width: 252px
}
#content .contact_form_right .row .large1 {
		width: 178px;
}
#content .contact_form_right .row.submit{
		text-align: center;
}
#content .contact_form_right .row.submit input:hover{
		background-position: 0 -159px;
		height: 30px;
	line-height: 18px;
		cursor: pointer;
}
#content .contact_form_right .row.submit input {
		background: url("img/button.png") no-repeat scroll 0 -131px;
		color: #fff;
		font-weight: bold;
		padding-right: 23px;
		width: 82px;
		height: 30px;
}

#c_contact .help_contact_product {
		float: left;
		margin-right: 10px;
		width: 290px;
}
.recaptcha_nothad_incorrect_sol.recaptcha_isnot_showing_audio {
		float: left;
}

/**** .product_run *****/
#content .product_run .caroufredsel_wrapper{
	margin-top: 5px !important;
	overflow: hidden;
}
#content .product_run ul{
	list-style: none;
	padding: 0;
	margin: 0;
	overflow: hidden;
}
#content .product_run ul li{
	float: left;
	text-align: left;
	border: 1px solid #ddd;
	width: 248px;
	height: 90px;
	margin: 2px;
	padding: 5px 3px;
}
#content .product_run ul li .thumb{
	float: left;
	margin: 0 3px 2px 0;
	position: relative;
}
#content .product_run ul li .thumb span{
	position: absolute;
	background: red;
	display: block;
	width: 30px;
	height: 30px;
	background: url("img/icon.png");
}
#content .product_run ul li .thumb img:hover{
	opacity: 0.5;
}
#content .product_run ul li .thumb span.discount{
	top: 0;
	left: 0;
	background-position: 0 0;
	color: #fff;
	text-align: center;
	line-height: 30px;
}
#content .product_run ul li .thumb span.promotion{
	top: 30px;
	left: 0;
	background-position: -30px 0;
}
#content .product_run ul li p.name{
	font-weight: bold;
	font-size: 12px;
}
#content .product_run ul li p.price{
	color: #ff0000;
}
#content .product_run ul li p.price span.old{
	display: block;
	text-decoration: line-through;
	color: #777;
}


/**** .sitemap ****/
#content .box_content.sitemap ul{
	margin: 10px 0 0 20px;
	padding: 0;
	list-style: none;
}
#content .box_content.sitemap ul li a{
	color: #696969;
	font-weight: bold;
}
#content .box_content.sitemap ul ul a{
	font-weight: normal;
}
#content .box_content.sitemap ul li{
	background: url(img/arrow3.png) left 6px no-repeat;
	padding-left: 12px;
}
#content .box_content.sitemap ul ul{
	padding-bottom: 10px;
}
#content .box_content.sitemap ul ul li{
	background: url(img/arrow4.png) left 6px no-repeat;
}


/**** .search *****/
#content .search{
	background: #eee;
	border-radius: 3px;
	padding: 16px 10px 18px;
	border: 1px solid #ddd;
	margin-bottom: 15px;
}
#content .search ul{
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
	width: 100%;
}
#content .search ul li{
	margin: 5px 10px 5px 0;
	float: left;
		height: 26px;
}
#content .search .submit input{
	background: url("img/button.png") 0 0 no-repeat;
	border: none;
	width: 110px;
	height: 31px;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 10px;
	padding-right: 18px;
	margin-top: 5px;
}
#content .search .submit input:hover{
	background-position: 0 -32px;
	cursor: pointer;
}

.search .submit {
		margin-right: 8%;
		text-align: right;
}

/**** .banner ****/
#content .banner{
	text-align: center;
	margin-bottom: 10px;
	border-radius: 5px;
	overflow: hidden;
	background:white;
	padding-top:10px;
}


#content .box_content .box_product.home, #content .box_content .box_product.promotion{
	width: 24%;
	margin: 7px 7px 15px 3px;
}
#content .box_content .box_product .top,#content .box_content .box_product .bottom
	{
	position: absolute;
	width: 100%;
	height: 5px;
	background: transparent;
}

/***************box_member_register****************/
#content .box_member_register{
	width:100%;
	margin-top:10px;
}
#content .box_member_register .unit{
	width:46%;
	float:left;
	border:1px solid #cecece;
	border-radius:5px;
	padding:10px;
	height:150px;
	position:relative;
}
#content .box_member_register .unit.left{
	margin-right:5px;
}
#content .box_member_register .unit h4{
	padding-bottom:10px;
	border-bottom:1px solid #cecece;
}
#content .box_member_register .unit p{
	padding-top:10px;
}
#content .box_member_register .edit{
	position:absolute;
	right:10px;
	bottom:10px;
}

/***************box_member_change_account****************/
#content .box_content.change_account{
	width:100%;
	margin:0px auto;
	background:white;
}

#content .box_content.change_account div{
	margin-top:10px;
}
#content .box_content.change_account .des{
	margin-top:-1px;
/* 	border:1px solid #cecece; */
	padding:10px !important;
}
#content .box_content.change_account label{
	display:block;
	width:100px;
	float:left;
	clear:left;
}
#content .box_content.change_account label span{
	color:red;
}
#content .box_content.change_account .info_mail{
	font-weight:bold;
}
#content .box_content.change_account .submit p{
	margin-left:100px;
	font-style:italic;
}
#content .box_content.change_account .submit span{
	color:red;
}
#content .box_content.change_account .submit input{
	background-image: url("img/button.png");
		background-position: -101px -132px;
		border:none;
		color: #FFFFFF;
		height: 24px;
		margin-bottom: 0;
		width: 97px;
	text-align:center;
	margin-left:100px;
	margin-top:10px;
	cursor:pointer;
}
#content .box_content.change_account .submit input:hover{
	background-image: url("img/button.png");
		background-position: -101px -156px;
}
#content .box_content .box_product .thumb {
	position: relative;
	text-align: center;
	overflow: hidden;
	margin-right:10px;
	margin: 0;
}

#content .box_content .box_product .thumb a{
	display: block;
	line-height: 1px;
}

#content .box_content .box_product .thumb span {
	display: block;
	width: 30px;
		z-index: 999;
	height: 30px;
	line-height: 30px;
	text-align: center;
	position: absolute;
	background-image: url(img/icon.png);
	color: #fff;
	font-size: 11px;
	font-weight: bold;
	text-decoration: blink;
}

#content .box_content .box_product .thumb span.discount {
	top: 0;
	right: 0;
	background-position: 0 0;
}

#content .box_content .box_product .thumb span.promotion {
	top: 30px;
	right: 1px;
	background-position: -30px 0;
}


#content .box_content .box_product .info {
		float: left;
		padding: 10px;
/*     width: 70%; */
	overflow: hidden;
	@media(min-width: 768px) {
	    min-height: 112px;
	}
}
/* #content .box_content .box_product.promotion  .info { */
/*     float: right; */
/*     padding: 10px 0 10px 20px; */
/*     width: 61%; */
/* } */
#content .box_content .box_product a {
		color: #696969;
		font-family: tradegothicltBold20;
		font-size: 19px;
}
#content .box_content .box_product p {
	text-align: left;
}
#content .box_content .box_product p.name,
#content .box_content .box_product p.price{
	overflow: hidden;
}
#content .box_content.promotion .title .title_right .title_center span{
	background: #c10d0e;
	color: #fff;
	float: left;
	border-bottom: none;
}
#content .box_content.promotion .title_center {
		border-bottom: 2px solid #c10d0e;
}
#content .box_content .box_product p.name {
/*     border-bottom:1px dashed #555; */
		font-size: 18px;
/*     margin-bottom: 5px; */
/*     padding-bottom: 8px; */
}
#content .box_content .box_product .description{
/* 	width:74%; */
	float:left;
}
#content .box_content .box_product .ribbon{
	float:left;
/* 	padding-left:10px; */
		width: 100%;
	position:relative;
}
#content .box_content .box_product.last{
	margin-right: 0;
}
#content .box_content .box_product span.price {
	color: #ff0000;
	font-weight: bold;
}
#content .box_content .box_product .des_scroll .name{
	font-size:14px;
	font-weight:bold;
}
#content .box_content .box_product .des_scroll p{
	margin:0px;
	padding:0px;
}
#content .box_content .box_product .des_scroll .price .lab,
#content .box_content .box_product .des_scroll .warranty span,
#content .box_content .box_product .des_scroll .promotion span{
	font-weight:bold;
	color:#333;
}
#content .box_content .box_product span.price span.old {
	color: #999;
	text-decoration: line-through;
	font-weight: normal;
}
#content .box_content .box_product p.cart{
	width: 100%;
	padding: 0;
/* 	display: none; */
}
#content .box_content .box_product:hover p.cart{
	display: block;
}

#content .box_content .box_product p.cart a {
		background-image: url("img/c_button_cart.png");
		background-position: 0 top;
		color: #fff;
		display: block;
		font-size: 16px;
		font-weight: bold;
		height: 28px;
		line-height: 28px;
		margin: 0 auto;
		text-align: center;
		text-transform: uppercase;
		width: 105px;
}

#content .box_content .box_product p.cart a:hover {
	background-position: 0 28px;
	text-decoration: none;
}
/***************.box_content_change_address****************/
#content .box_content.change_address .des{
	padding:10px;
	width:auto;
	margin:0px;
	margin-top:-1px;
}
#content .box_content.change_address{
	width:100%;
	margin:0px auto;
	background:white;
}
#content .box_content.change_address div{
	margin-top:10px;
}
#content .box_content.change_address label{
	width:100px;
	display:block;
	float:left;
	clear:left;
}
#content .box_content.change_address label span{
	color:red;
}
#content .box_content.change_address .submit p{
	margin-left:100px;
	font-style:italic;
}
#content .box_content.change_address .submit span{
	color:red;
}
#content .box_content.change_address .submit input{
	background-image: url("img/button.png");
		background-position: -101px -132px;
		border:none;
		color: #FFFFFF;
		height: 24px;
		margin-bottom: 0;
		width: 97px;
	text-align:center;
	margin-left:100px;
	margin-top:10px;
	cursor:pointer;
}
#content .box_content.change_address .submit input:hover{
	background-image: url("img/button.png");
		background-position: -101px -156px;
}

/****. history_payment ****/
.box_content.history_payment table{
	width: 100%;
}
.box_content.history_payment table td,
.box_content.history_payment table th{
	border: 1px solid #aaa;
	padding: 0 3px;
	text-align:center;
}
.box_content.history_payment table tr td.notice{
	height:25px;
}
.box_content.history_payment table span.time{
	font-size: 10px;
	font-style: italic;
	display: block;
	line-height: 15px;
	color: #666
}
.box_content.history_payment table th{
	background: #eee;
	line-height: 28px;
}
.box_content.history_payment table .small{
	text-align: center;
	width: 1px;
	white-space: nowrap;
}
.box_content.history_payment table.info th{
	width: 150px;
	text-align: left;
	background: none;
}
.box_content.history_payment h3{
	margin: 15px 0px 5px 0px;

}
.box_content.history_payment .detail_order p.old{
	text-decoration:line-through;
	color:#555555;
}
.box_content.history_payment .detail_order p.promotion{
	color:red;
}
.box_content.history_payment table tr.total th{
	background:none;
	text-align:right;
	border:none;
	color:red;
}
.box_content.history_payment table tr.total td{
	border:none;
	color:red;
	font-weight:bold;
	text-align:center;
}
/**.management_notice**/
.box_content.history_payment.management_notice tr td.No_Read a{
	font-weight:bold;
}

/***** .list_hotel *****/
.list_hotel .box_content .box_hotel{
	width: 100%;
	margin-bottom: 10px;
	float: left;
/* 	border-bottom: 1px solid #f5f5f5; */
	background:white;
}
.list_hotel .box_content .box_hotel p.name{
	font-weight: bold;
	margin-bottom: 10px;
}
.list_hotel .box_content .box_hotel .info .address{
	font-weight: bold;
	margin-bottom: 5px;
}
.list_hotel .box_content .box_hotel .info{
	text-align: justify;
}
.list_hotel #content .box_content .box_hotel p.name a{
	color: #2980b9;
	font-size: 15px;
}
.list_hotel .box_content .box_hotel .thumb{
	float: left;
	width: 200px;
	margin-right: 15px;
}
.list_hotel .box_content .box_hotel .info{
	float: left;
	width: 466px;
	padding:10px 0;
}

/***** .list_category *****/
#content .list_category {
	margin: 8px 0;
	padding: 8px;
	overflow: hidden;
	border: 1px solid #E8E8E8;
	background-color: #f9f9f9;
	border-radius: 5px;
	overflow: hidden;
}

#content .list_category li {
	list-style: none;
	width: 186px;
	float: left;
/*	border-bottom: 1px dotted #ddd;*/
	padding: 3px 0;
	margin-right: 4px;
}

#content .list_category li .thumb {
	float: left;
	margin-right: 10px;
}
#content .list_category .thumb a{
	line-height: 1px;
	display: block;
}
#content .list_category li a {
	line-height: 29px;
	color: #696969;
}

/***** .list_category2 *****/
#content .list_category2{
	list-style: none;
	margin: 0;
	padding: 0;
}
#content .list_category2 li{
	float: left;
	width: 173px;
	text-align: center;
	height: 192px;
	overflow: hidden;
}
#content .list_category2 li a{
	color: #696969;
}
#content .list_category2 li a.thumb{
	display: block;
	margin-bottom: 5px;
}


/***** .list_document *****/
#content .box_content ul.list_document{
	list-style: none;
	margin: 0;
	padding: 0;
}
#content .box_content ul.list_document li{
	display: block;
	padding: 5px;
}
#content .box_content ul.list_document a{
	color: #696969;
}
#content .box_content ul.list_document li a.name{
	margin-left: 5px;
}
#content .box_content ul.list_document li a.more{
	margin-left: 8px;
}
#content .box_content ul.list_document li.bold{
	background-color: #eee;
}
#content .box_content ul.list_document .detail{
/*	display: none;*/
}
#content .box_content ul.list_document .left{
	float: left;
}
#content .box_content ul.list_document .right{
	float: right;
}
#content .box_content ul.list_document .summary{
	overflow: hidden;
}
#content .box_content ul.list_document .detail{
	border-top: 1px solid #ddd;
	margin-top: 8px;
	padding: 3px 0;
	font-size: 0.9em;
	display: none;
	color: #555;
	text-align: justify;
}


/***** .box_post *****/
#content .box_content .box_post {
	width: 100%;
	padding: 0;
	float: left;
	position: relative;
	z-index: 1;
	margin: 5px 0;
	height:128px;
}

#content .box_content .box_post .top,
#content .box_content .box_post .bottom{
	position: absolute;
	width: 100%;
	height: 5px;
	z-index: 10;
	background: transparent;
}

#content .box_content .box_post .top {
	top: -5px;
}

#content .box_content .box_post .bottom {
	bottom: -5px;
}

#content .box_content .box_post .thumb {
	float: left;
	margin: 0 15px 0 0;
}

#content .box_content .box_post:hover .thumb {
/* 	border: 1px solid #aaa; */
}

#content .box_content .box_post a.name {
	font-weight: bold;
	line-height: 16px;
	color: #696969;
}
#content .box_content .box_post p.datetime{
	font-size: 11px;
	color: #666;
	font-style: italic;
	display:none;
}
#content .box_content .box_post p {
	text-align: justify;
}
#content .box_content .box_post p.more {
		text-align: right;
}

#content .box_content .box_post a.more {
	font-style: italic;
}


/***** .order *****/
#content .order header.title{
	margin-bottom: 10px;
}
#content .order .form{
	float: left;
	width: 400px;
	border: 1px solid #ddd;
	padding: 10px 62px;
}
#content .order .form form{
	margin-top: 10px;
}
#content .order .form .submit{
	text-align: center;
	margin-top: 10px;
}
#content .order .form .submit input{
	background: url(img/button.png) -202px -66px no-repeat;
	border: none;
	width: 111px;
	height: 28px;
	color: #fff;
	font-weight: bold;
}
#content .order .form .submit input:hover{
	background-position: -202px -94px;
	cursor: pointer;
}
#content .order .form label{
	display: block;
	font-size: 12px;
	font-weight: bold;
}
#content .order .form .row{
	margin-bottom: 5px;
}
#content .order .map {
	border: 1px solid #d9d9d9;
}
#content .order input.larger{
	width: 386px;
}
#content .order input.larger1{
	width: 312px;
}
#content .order textarea.medium{
	width: 386px;
}
#content .order .form .row span.t{
	font-weight: bold;
	margin-right: 10px;
	display: block;
	width: 80px;
	float: left;
}
#content .confirm .form .row{
	border-bottom: 1px dotted #ddd;
	padding-bottom: 5px;
	text-align: justify;
	overflow: hidden;
}
.order .booking_form {
		background: none repeat scroll 0 0 #fff;
		padding: 20px !important;
}
/*** .thank ***/
#content .order.thank .des{
	background: #f9f9f9;
	border-radius: 5px;
	padding: 20px 10px;
	border: 1px solid #eee;
	color: green;
	font-size: 14px;
}

/*** ..order_info ***/
#content .order .order_info{
	float: right;
	border: 1px solid #ddd;
	margin-right: 1px;
	width: 410px;
	font-size: 12px;
	overflow: hidden;
}
#content .order .order_info span.title{
	background: #696969;
	color: #fff;
	font-weight: bold;
	display: block;
	text-align: center;
	font-size: 13px;
}
#content .order .order_info table{
	width: 100%;
}
#content .order .order_info table th{
	padding: 5px 0;
	text-align: left;
	background: #f5f5f5
}
#content .order .order_info table th{
	border-right: 1px solid #eee;
	border-bottom: 1px solid #eee;
	padding: 5px;
}
#content .order .order_info table .name{
	font-weight: bold;
}
#content .order .order_info table .status{
	color: green;
}
#content .order .order_info table .promotion{
	color: #ff0000;
	font-style: italic;
}
#content .order .order_info table .old{
	text-decoration: line-through;
	color: #555;
}
#content .order .order_info table .new{
	font-weight: bold;
}
#content .order .order_info table .discount{
	color: #ff0000;
	font-style: italic;
}
#content .order .order_info table .center{
	text-align: center;
}

#content .order .order_info table td{
	padding: 0 5px;
	border-bottom: 1px solid #eee;
	border-right: 1px solid #eee;
}
#content .order .order_info table th:last-of-type,
#content .order .order_info table td:last-of-type{
	border-right: none;
}
#content .order .order_info table tr.total th{
	text-align: right;
	background: none;
}
#content .order .order_info table tr.total th,
#content .order .order_info table tr.total td{
	border-top: 1px dotted #aaa;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	color: #ff0000;
	white-space: nowrap;
	border: none;
}

/***** .box_video *****/
#content .box_content .box_video {
	width: 244px;
	height: 120px;
	margin: 2px;
	float: left;
	position: relative;
	text-align: center;
	border: 1px solid #eee;
	padding: 5px;
}
#content .box_content .related .box_video{
	height: 130px;
}
#content .box_content .box_video:hover{
	border: 1px solid #aaa;
}
#content .box_content .box_video a{
	color: #696969;
}
#content .box_content .box_video .top,#content .box_content .box_video .bottom
	{
	position: absolute;
	width: 100%;
	height: 5px;
	background: transparent;
	left: 0px;
}

#content .box_content .box_video .top {
	top: -5px;
}

#content .box_content .box_video .bottom {
	bottom: -5px;
}

#content .box_content .box_video .thumb {
	padding: 2px;
}

#content .box_content .box_video a.name {
	line-height: 16px;
}

#content .box_content .video_play {
	text-align: center;
	margin-bottom: 10px;
}

/***** .tooltip_content ******/
.tooltip_content {
	display: none;
}

#tooltipContent p.name {
	font-weight: bold;
	padding-bottom: 5px;
}

#tooltipContent p.price span.old {
	color: #999;
	text-decoration: line-through;
}

#tooltipContent p.price span.new {
	color: #ff0000;
	font-weight: bold;
}

#tooltipContent p.promotion {
	padding: 8px 0;
	margin: 5px 0;
	background: #f9f9f9;
	color: #ff0000;
	font-style: italic;
	border-bottom: 1px solid #eee;
	border-top: 1px solid #eee;
}

#tooltipContent .des2 {
	font-size: 12px;
	color: #444
}

/*** .social ****/
#content ul.social{
	margin: 10px 0;
	padding: 0;
	list-style: none;
	overflow: hidden;

}
#content ul.social li{
	display: block;
	float: left;
}
#content ul.social li.facebook{
	width: 100px;
}
#content ul.social li.linkedin{
	padding-right: 15px;
}

#content .read .des table,
#content .read .des table th,
#content .read .des table td{
	border: 1px solid #aaa;
}
#content .read .des table th,
#content .read .des table td{
	padding: 3px 5px;
}
#content .read .des p{
	margin: 8px 0;
	line-height: 20px;
	color: #222;
}
#content .des a{
	color: blue;
}

/****** .related *******/
#content .box_content .related {
		/**
 * display: none;
 */
}
#content .box_content .related .title{
	margin:10px 0!important;
}

#content .box_content .related span.title {
	display:block;
	font-weight: bold;
	font-size: 18px;
	background: transparent;
	padding: 0;
	color: #555;
	height: auto;
	line-height: 15px;
	margin: 20px 0 10px;
}

#content .box_content .related ul {
	margin: 0 0 0 6px;
	padding: 0;
}

#content .box_content .related li {
	list-style: none;
}

#content .box_content .related li a {
	background: url(img/arrow2.png) left 6px no-repeat;
	padding-left: 8px;
	color: #696969;
}

/****** .gallery ******/
#content .box_content .box_gallery {
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0 0 3px #ddd;
	width: 257px;
	text-align: center;
	padding: 3px;
	float: left;
	margin: 3px;
}

#content .box_content .box_gallery:hover {
	border: 1px solid #aaa;
}

#content .box_content .box_gallery p.name {
	padding: 0 5px;
	height: 19px;
	overflow: hidden;
}
#content .box_content .box_gallery a{
	color: #696969;
}

/****** .gallery_detail *****/
.detail_gallery #content #slideshow_image {
	position: relative;
	overflow: hidden;
	background: #000;
	margin-bottom: 10px;
}

.detail_gallery #content .galleria-stage {
	position: absolute;
	top: 10px;
	bottom: 60px;
	left: 10px;
	right: 10px;
	overflow: hidden;
}

.detail_gallery #content .galleria-thumbnails-container {
	height: 50px;
	bottom: 0;
	position: absolute;
	left: 10px;
	right: 10px;
	z-index: 2;
}

.detail_gallery #content .galleria-carousel .galleria-thumbnails-list {
	margin-left: 30px;
	margin-right: 30px;
}

.detail_gallery #content .galleria-thumbnails .galleria-image {
	height: 40px;
	width: 60px;
	background: #000;
	margin: 0 5px 0 0;
	float: left;
	cursor: pointer;
}

.detail_gallery #content .galleria-counter {
	position: absolute;
	bottom: 10px;
	left: 10px;
	text-align: right;
	color: #fff;
	font: normal 11px/1 arial, sans-serif;
	z-index: 2;
}

.detail_gallery #content .galleria-loader {
	background: #000;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 2;
	display: none;
	background: url(img/classic-loader.gif) no-repeat 2px 2px;
}

.detail_gallery #content .galleria-info {
	width: 50%;
	top: 15px;
	left: 15px;
	z-index: 2;
	position: absolute;
}

.detail_gallery #content .galleria-info-text {
	background-color: #000;
	background-color: rgba(0, 0, 0, .9);
	padding: 12px;
	display: none;
}

.detail_gallery #content .galleria-info-title {
	font: bold 12px/1.1 arial, sans-serif;
	margin: 0;
	color: #fff;
}

.detail_gallery #content .galleria-info-description {
	font: italic 12px/1.4 georgia, serif;
	margin: 0;
	color: #bbb;
}

.detail_gallery #content .galleria-info-title+.galleria-info-description
	{
	margin-top: 7px;
}

.detail_gallery #content .galleria-info-close {
	width: 9px;
	height: 9px;
	position: absolute;
	top: 5px;
	right: 5px;
	background-position: -753px -11px;
	opacity: .5;
	cursor: pointer;
	display: none;
}

.detail_gallery #content .galleria-info-link {
	background-position: -669px -5px;
	opacity: .8;
	position: absolute;
	width: 20px;
	height: 20px;
	cursor: pointer;
	background-color: #000;
}

.detail_gallery #content .galleria-info-link:hover,.detail_gallery #content .galleria-info-close:hover
	{
	opacity: .5;
}

.detail_gallery #content .galleria-image-nav {
	position: absolute;
	top: 50%;
	margin-top: -15px;
	width: 100%;
	height: 31px;
	left: 0;
}

.detail_gallery #content .galleria-image-nav-left,.detail_gallery #content .galleria-image-nav-right
	{
	opacity: .7;
	cursor: pointer;
	width: 16px;
	height: 31px;
	position: absolute;
	left: 10px;
	z-index: 2;
}

.detail_gallery #content .galleria-image-nav-right {
	left: auto;
	right: 10px;
	background-position: -300px 0;
	z-index: 2;
}

.detail_gallery #content .galleria-image-nav-left:hover,.detail_gallery #content .galleria-image-nav-right:hover
	{
	opacity: .5;
}

.detail_gallery #content .galleria-thumb-nav-left,.detail_gallery #content .galleria-thumb-nav-right
	{
	cursor: pointer;
	display: none;
	background-position: -495px 5px;
	position: absolute;
	left: 0;
	top: 0;
	height: 40px;
	width: 23px;
	z-index: 3;
	opacity: .8;
}

.detail_gallery #content .galleria-thumb-nav-right {
	background-position: -578px 5px;
	border-right: none;
	right: 0;
	left: auto;
}

.detail_gallery #content .galleria-thumbnails-container .disabled,.detail_gallery #content .galleria-thumbnails-container .disabled:hover
	{
	opacity: .2;
	cursor: default;
}

.detail_gallery #content .galleria-thumb-nav-left:hover,.detail_gallery #content .galleria-thumb-nav-right:hover
	{
	opacity: 1;
	background-color: #111;
}

.detail_gallery #content .galleria-carousel .galleria-thumb-nav-left,.detail_gallery #content .galleria-carousel .galleria-thumb-nav-right
	{
	display: block;
}

.detail_gallery #content .galleria-thumb-nav-left,.detail_gallery #content .galleria-thumb-nav-right,.detail_gallery #content .galleria-info-link,.detail_gallery #content .galleria-info-close,.detail_gallery #content .galleria-image-nav-left,.detail_gallery #content .galleria-image-nav-right
	{
	background-image: url(img/classic-map.png);
	background-repeat: no-repeat;
}

/****** .box_content_faq ******/
#content .box_content_faq ul.question {
	margin-top: 5px;
}

#content .box_content_faq ul.question li {
	list-style: decimal;
}

#content .box_content_faq h2 {
	line-height: 35px;
}

#content .box_content_faq .answer {
	margin-bottom: 10px;
	text-align: justify;
}
#content .box_content_faq .line{
	border-bottom: 1px solid #ddd;
	margin: 7px 0 5px;
}
#content .box_content_faq .highline{
	background: yellow;
	display: inline;
}

/****** .contact ******/
.contact #content .des{
	padding-top: 10px;
}
.contact #content .info {
	float: left;
	width: 539px;
	margin-right: 22px;
}
.contact #content .info table,
.contact #content .info table td,
.contact #content .info table th{
	border: none;
}
.contact #content .info address{
/* 	height: 182px; */
	margin-bottom: 10px;
}
.contact #content .form{
	padding: 10px 12px;
	float: left;
	border: 1px solid #ddd;
	background: #FCFCFC;
}
.contact #content .form .title{
	background: none;
	padding: 0;
	color: #E20909;
	font-style: italic;
	font-size: 12px;
	line-height: 15px;
}
.contact #content .form form{
	margin-top: 10px;
}
.contact #content .form .submit{
	text-align: center;
	margin-top: 10px;
}
.contact #content .form .submit input{
	background: url(img/button.png) 0px -132px no-repeat;
	border: none;
	width: 80px;
	height: 28px;
	padding-top: 0px;
	padding-right: 30px;
	color: #fff;
	font-weight: bold;
}
.contact #content .form .submit input:hover{
	background-position: -0px -160px;
	cursor: pointer;
}
.contact #content .form label{
	display: block;
	font-size: 12px;
	font-weight: bold;
		margin-bottom: 3px;
	float:left;
	width: 125px;
}
.recaptcha_nothad_incorrect_sol.recaptcha_isnot_showing_audio {
		float: left;
}
.contact #content input.larger_1 {
		width: 232px;
}
.contact #content .form .row{
	margin-bottom: 5px;
}
.contact #content .map {
	border: 1px solid #d9d9d9;
}
.contact #content input.larger{
	width: 306px;
}
.contact #content input.larger1{
	width: 232px;
}
.contact #content textarea.medium{
	width: 306px;
}
.captcha > td {
		float: left;
}
/****** .register ******/
.registration .des{
	border:1px solid #cecece;
	margin-top:-1px;
	padding:10px !important;
}
.registration .form_register{
	width:100%;
}
.registration .form_register div{
	margin:10px auto;
}
.registration .form_register div label{
	width:150px;
	display:block;
	float:left;
	clear:left;
	line-height:25px;
}
.registration .form_register div label.error{
	margin-left:200px;
}

.registration .form_register .submit input{
	background-image: url("img/button.png");
		background-position: -101px -132px;
		border: medium none;
		color: #FFFFFF;
		display: inline;
		height: 24px;
		line-height: 15px;
		margin-right: 15px;
		padding: 0;
		text-align: center;
		width: 97px;
	margin-left:150px;
	margin-top:10px;
	float:left;
	clear:left;
	cursor:pointer;
}
.registration .form_register .submit input:hover{
	background-position: -101px -156px;
}

/****** .forget_password ******/
.forget_password .des{
	border:1px solid #cecece;
	padding:10px !important;
	margin-top:-1px;
}
.forget_password .row{
	margin:10px 0px;
}
.forget_password label{
	width:150px;
	display:block;
	float:left;
	clear:left;
}
.forget_password .row input{
	width:309px;
}
.forget_password .submit input{
	background-image: url("img/button.png");
		background-position: -101px -132px;
		border: medium none;
		color: #FFFFFF;
		display: inline;
		height: 24px;
		line-height: 24px;
		margin-right: 15px;
		padding: 0;
		text-align: center;
		width: 97px;
	margin-left:150px;
	float:left;
	clear:left;
	cursor:pointer;
}
.forget_password .submit input:hover{
	background-position: -101px -156px;
}
/*** .star_rate ***/
#content .bg_star_rate .star_rate{
	float: left;
	margin-right: 10px;
}
#content .bg_star_rate p.c{
		white-space: nowrap;
		font-style: italic;
}
#content .star_rate ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

#content .star_rate,#content .star_rate_2
	{
	background-image: url(img/star.gif);
	height: 23px;
}

#content .star_rate {
	width: 124px;
	overflow: hidden;
	position: relative;
	background-position: left top;
	margin-bottom: 5px;
}
#content .star_rate p{
	margin: 0 !important;
}

#content .star_rate_2 {
	background-position: 0 -24px;
	position: absolute;
}

#content .star_rate_3 {
	position: absolute;
}

#content .star_rate_3 a {
	position: absolute;
	top: -1px;
}

#content .star_rate_3 a:hover {
	display: block;
	background-image: url(img/star.gif);
	background-position: 0 -48px;
}

#content .star_rate_3 a {
	text-decoration: none;
	height: 23px;
	z-index: 10;
}

#content .star_rate_3 a.star1 {
	width: 25px;
	z-index: 8;
}

#content .star_rate_3 a.star2 {
	width: 50px;
	z-index: 6;
}

#content .star_rate_3 a.star3 {
	width: 75px;
	z-index: 4;
}

#content .star_rate_3 a.star4 {
	width: 100px;
	z-index: 2;
}

#content .star_rate_3 a.star5 {
	width: 125px;
	z-index: 1;
}

/*** .tabs ***/
#content ul.tabs {
	float: left;
	margin: 0;
	padding: 0;
}

#content ul.tabs li {
	float: left;
	height: 30px;
	line-height: 30px; /*--Vertically aligns the text within the tab--*/
	margin-bottom: -1px; /*--Pull the list item down 1px--*/
	overflow: hidden;
	position: relative;
	background: #2980b9;
	margin-right: 2px;
}

#content ul.tabs li a,#content ul.tabs li {
}

#content ul.tabs li a {
	text-decoration: none;
	color: #fff;
	display: block;
	font-size: 13px;
	padding: 0 15px;
	outline: none;
	float: left;
}

#content ul.tabs li a:hover {
	background: #c10d0e;
}

#content ul.tabs li.active,
#content ul.tabs li.active a:hover {
	/*--Makes sure that the active tab does not listen to the hover properties--*/
	background: #c10d0e;
	border-bottom: 2px solid #c10d0e;
	/*--Makes the active tab look like it's connected with its content--*/
	font-weight: bold;
}
#content .tab_container {
		-moz-border-bottom-colors: none;
		-moz-border-left-colors: none;
		-moz-border-right-colors: none;
		-moz-border-top-colors: none;
		-webkit-border-bottom-colors: none;
		-webkit-border-left-colors: none;
		-webkit-border-right-colors: none;
		-webkit-border-top-colors: none;
		border-color: #c10d0e #cfcfcf #cfcfcf;
		border-image: none;
		border-right: 1px solid #cfcfcf;
		border-style: solid;
		border-width: 2px 1px 1px;
		clear: both;
		float: left;
		margin-bottom: 8px;
		overflow: hidden;
}

/**Tags**/
#content .list_tag .tab_content {
		padding: 10px 5px;
}



/******** #breadcrumb *******/
#breadcrumb{

}
#breadcrumb ul {
	margin: 3px 0 0 0;
	padding: 0 0 0 5px;
	font-size: 12px;
	float: left;
	color: #555
}

#breadcrumb ul li {
	display: block;
	float: left;
	margin-right: 5px;
	position: relative;
}

#breadcrumb ul li a {
	background: url(img/c_breadcrumb.png) right 5px no-repeat;
	padding-right: 10px;
	color: #555
}

#breadcrumb ul li.home {
	background: url(img/c_home.png) center left no-repeat;
}

#breadcrumb ul li.home a {
	padding: 0 12px;
}

#breadcrumb ul li ul {
	display: none;
	position: absolute;
	top: 15px;
	background: #f9f9f9;
	z-index: 1000;
	box-shadow: 0 0 2px #222;
}

#breadcrumb ul li:hover ul {
	display: block;
}

#breadcrumb ul li ul li {
	width: 200px;
	padding: 3px 0;
	display: block;
	margin: 0;
	border-bottom: 1px solid #eee;
	background: url('img/arrow1.png') 4px 10px no-repeat;
}
#breadcrumb ul li ul li:hover{
	background-position: 5px 10px;
}

#breadcrumb ul li ul li a {
	background: none;
	padding: 0 0 0 10px;
}

/********* ul.step *********/
#content ul.step {
	margin: 0;
	padding: 0;
	float: left;
	width: 100%;
	margin-bottom: 15px;
	border-collapse: collapse;
}

#content ul.step li {
	display: block;
	float: left;
	width: 25%;
	line-height: 48px;
	font-size: 16px;
	font-weight: bold;
	color: #444;
}

#content ul.step li span {
	font-size: 25px;
	padding: 0 12px;
	display: block;
	width: 25px;
	margin-right: 5px;
	float: left;
	background: url(img/button.png) -151px -49px no-repeat;
	color: #fff;
}

#content ul.step li.active span {
	background-position: -151px 0;
}

#content ul.step li.active {
	color: #ff0000;
}

/**** #adv_left_out #adv_right_out ****/
#adv_left_out,
#adv_right_out{
	position: fixed;
	top: 5px;
	margin: 0;
	padding: 0;
	list-style: none;
	border-radius: 2px;
	width: 149px;
	text-align: center;
}
#adv_left_out{
	left: 10px;
}
#adv_right_out{
	right: 10px;
}



/******** #cart *******/
#cart{
	position: fixed;
	bottom: 0;
	right: 15px;
	width: 200px;
	background: #fff;
	border: 1px solid #ff0000;
	z-index: 1000;
	overflow: hidden;
	border-radius: 2px 2px 0 0;
	text-align: center;
	box-shadow: 0px -1px 3px #aaa;
}
#cart a.title{
	background: url(img/pupup_cart.png) center top repeat-x;
	color: #fff;
	font-weight: bold;
	padding: 0;
	line-height: 25px;
	display: block;
	text-transform: uppercase;
	font-size: 12px;
}
#cart a.title:hover{
	text-decoration: none;
}
#cart div{
	line-height: 22px;
	padding: 6px;
	display: none;
}
#cart p.link a{
	color: #ff0000;
}


/******** #support_2 *******/
#support_2{
	position: fixed;
	bottom: 0;
	right: 15px;
	width: 200px;
	background: #fff;
	border: 1px solid #006cb5;
	z-index: 10000;
	border-radius: 2px 2px 0 0;
	text-align: center;
	box-shadow: 0px -1px 3px #aaa;
}
#support_2 span.icon{
	background: url(img/icon_support.png) no-repeat;
	width: 48px;
	height: 48px;
	position: absolute;
	top: -15px;
	left: 2px;
}
#support_2 a.title{
	background: url(img/pupup_support.png) center top repeat-x;
	color: #fff;
	font-weight: bold;
	padding: 0;
	line-height: 25px;
	display: block;
	text-transform: uppercase;
	font-size: 12px;
	padding-left: 20px;
}
#support_2 a.title:hover{
	text-decoration: none;
}
#support_2 div{
	line-height: 22px;
	padding: 15px 6px 10px;
	display: none;
	overflow: hidden;
}
#support_2 ul{
	margin: 0;
	padding: 0;
	list-style: none;
}
#support_2 ul li{
	padding: 3px 0;
	overflow: hidden;
}
#support_2 ul p{
	float: left;
}
#support_2 ul p.name{
	text-align: left;
	width: 147px;
}
#support_2 ul p.nick{
	width: 20px;
}
#support_2 ul a:hover{
	text-decoration: none;
}

/*** popup ****/
#mask {
	position:fixed;
	left:0;
	top:0;
	z-index:10000;
	background-color:#000;
	display:none;
	opacity: 0.8;
}
#popup .window {
	position:absolute;
	left:0;
	top: 100px;
	width:820px;
	display:none;
	z-index:9999;
	color: #fff;
	width: 500px;
	text-align: justify;
	z-index: 11000;
}
#popup a.close{
	padding: 5px 12px;
	float: right;
	margin-right: -13px;
	margin-top: -13px;
	background: url(img/icon.png) -92px 0 no-repeat;
}
#popup a.close:hover{
	background-position: -122px 0;
	text-decoration: none;
}


/**** #popup_cart *****/
#popup #popup_cart{
	background: #eee;
	height: 400px;
	width: 700px !important;
	color: #696969;
	border-radius: 2px;
	overflow: hidden;
}
#popup #popup_cart span.title{
	color: #fff;
	font-weight: bold;
	font-size: 15px;
	line-height: 32px;
	background: #D64937;
	display: block;
	padding-left: 10px;
	text-transform: uppercase;
}
#popup #popup_cart span.title span{
	padding: 4px 18px;
	background: url(img/icon.png) -185px -2px no-repeat;
	margin-right: 5px;
}
#popup #popup_cart .list_product{
	padding: 0 10px;
	height: 250px;
	overflow: auto;
	background: #fff;
	border-bottom: 1px solid #ddd;
}
#popup #popup_cart .list_product .new{
	font-weight: bold;
}
#popup #popup_cart .list_product .old{
	text-decoration: line-through;
	font-size: 12px;
}
#popup #popup_cart .list_product .discount{
	color: #ff0000;
	font-size: 12px;
	font-style: italic;
}
#popup #popup_cart table{
	margin-top: 15px;
	width: 100%;
	border: 1px solid #ddd;
}
#popup #popup_cart table .small{
	width: 1px;
	white-space: nowrap;
}
#popup #popup_cart table .center{
	text-align: center;
}
#popup #popup_cart .list_product table img{
	margin: 0 5px 2px 0;
}
#popup #popup_cart .list_product table .name{
	font-weight: bold;
}
#popup #popup_cart .list_product table p.status{
	color: green;
}
#popup #popup_cart .list_product table .promotion{
	color: #ff0000;
	font-style: italic;
}
#popup #popup_cart table th{
	line-height: 20px;
	background: #eee;
}
#popup #popup_cart table th,
#popup #popup_cart table td{
	border-bottom: 1px solid #ddd;
	padding: 6px;
}
#popup #popup_cart table td.price{
	font-weight: bold;
}
#popup #popup_cart .payment{
	width: 200px;
	float: right;
	margin-top: 15px;
}
#popup #popup_cart .payment p.total{
	font-weight: bold;
	color: #D64937;
	font-size: 15px;
	padding-bottom: 12px;
}
#popup #popup_cart .payment p.total span{
	display: block;
	width: 70px;
	float: left;
}
#popup #popup_cart .payment .close{
	background: none;
	float: none;
	padding: 0;
	margin: 0;
	color: green;
}
#popup #popup_cart .payment .close a:hover{
	text-decoration: underline;
}
#popup #popup_cart .payment p.payment2 a{
	background: #D14836;
	color: #fff;
	display: block;
	width: 175px;
	text-align: center;
	line-height: 28px;
	font-weight: bold;
	margin-bottom: 5px;
	border-radius: 3px;

}
#popup #popup_cart .payment p.payment2 a:hover{
	background: #C43C2D;
	text-decoration: none;
}

#popup #popup_cart .empty_order{
	text-align: center;
	margin-top: 100px;
	font-size: 18px;
}

/*******#popup_member************/
#popup_member{
	background: #fff;
	min-height: 250px;
	width: 410px !important;
	border-radius:7px;
	position:relative;
}
#popup_member .popup-header{
	height:30px;
	background:#2980b9;
	border-radius:7px 7px 0px 0px;
	line-height:30px;
	padding-left:27px;
	font-size:18px;
}
#popup_member .popup_form{
		width: 354px;
	margin:0px auto;
}
#popup_member .popup_form div{
	margin-top:10px;
}
#popup_member .popup_form div.error{
	height:13px;
	margin-top:0px;
}
#popup_member .popup_form div label{
	color: #666666;
		font-size: 14px;
		font-weight: bold;
		line-height: 15px;
		text-transform: uppercase;
	width:100px;
	display:block;
	margin-bottom:5px;
}
#popup_member .popup_form div.row input{
	border: 1px solid #CCCCCC;
		border-radius: 4px 4px 4px 4px;
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1) inset;
		color: #cecece;
		font-family: arial;
		font-size: 125%;
		line-height: 18px;
		padding: 8px 6px 7px;
		width: 340px;
}
#popup_member .popup_form p{
	padding-top:5px;
	font-style:italic;
}
#popup_member .popup_form div.button input{
	background-image: url("img/button.png");
		background-position: -101px -132px;
		color: #FFFFFF;
		display: inline;
		line-height: 24px;
		text-align: center;
		width: 97px;
	border:none;
	margin-right:15px;
	padding:0px;
	height:24px;
	cursor:pointer;
}
#popup_member .popup_form div.button input:hover{
	background-position: -101px -156px;
}

#popup_member .popup_form .error_none{
	display:none;
}
#popup_member .popup_form .error_show{
	display:block;
	color:red;
	font-style:italic;
	text-align:center;
	position:absolute;
	top:35px;
	left:80px;
}

/**** #lock_web ****/
#lock_web{
	position: fixed;
	z-index: 11000;
	top: 0;
	color: #000;
	width: 100%;
	height: 100%;
}
#lock_web .mask{
	opacity: 0.7;
	background: #696969;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
}
#lock_web .des{
	width: 600px;
	margin: 100px auto 0;
	background: #fff;
	border-radius: 6px;
	padding: 15px;
	font-size: 16px;
	line-height: 22px;
	min-height: 200px;
	max-height: 400px;
	position: relative;
	z-index: 12000;
	overflow: auto;
}


/**** #ajax_loading ****/
#message_top {
	display: none;
	top: 20px;
	float: right;
	position: fixed;
	z-index: 100000;
	width: 100%;
	text-align: center;
}
#message_top #loading{
	width: 160px;
	margin: 0 auto;
	padding: 30px 10px 15px;
	border-radius: 3px;
	box-shadow: 0px 0px 4px #696969;
	opacity: 0.9;
	background: #fff url(img/ajax-loading.gif) center 10px no-repeat;
}


/**** .box_banner ****/
.box_banner ul{
	list-style: none;
	padding: 0;
	margin: 0;
	overflow: hidden;
	width: 180px;
}
.box_banner ul li{
	float: left;
	border: 1px solid #ddd;
	margin: 2px 4px 2px 0;
}
.box_banner ul li:hover{
	border: 1px solid #999;
}

/**** .banner_bottom ****/
.box_banner.banner_bottom,
.box_banner.banner_bottom .caroufredsel_wrapper{
	width: 960px !important;
	overflow: hidden;
}
.box_banner.banner_bottom{
	margin-bottom: 8px;
}


/**** .box_partner ****/
.box_partner{
	margin-bottom: 18px;
	overflow: hidden;
	width: 960px;
}
.box_partner ul li{
	float: left;
	border: 1px solid #ddd;
	margin: 0 3px;
	display: block;
	height: 80px;
}
.box_partner ul li:hover{
	border: 1px solid #696969;
}
.box_partner span.title{
	font-size: 15px;
	font-weight: bold;
	padding-left: 8px;
	line-height: 25px;
}
.box_partner .caroufredsel_wrapper{
	margin: 0 auto !important;
	height: 82px !important;
}
.box_partner ul{
	height: 82px;
}

.simply-scroll-container { /* Container DIV - automatically generated */
	position: relative;
}

.simply-scroll-clip { /* Clip DIV - automatically generated */
	position: relative;
	overflow: hidden;
}

.simply-scroll-list { /* UL/OL/DIV - the element that simplyScroll is inited on */
	overflow: hidden;
	margin: 0;
	padding: 0;
	list-style: none;
}

.simply-scroll-list li {
	padding: 0;
	margin: 0;
	list-style: none;
}

.simply-scroll-list li img {
	border: none;
}

.simply-scroll-btn {
	position: absolute;
	background-image: url(buttons.png);
	width: 42px;
	height: 44px;
	z-index:3;
	cursor: pointer;
}

.simply-scroll-btn-left {
	left: 6px;
	bottom: 6px;
	background-position: 0 -44px;
}
.simply-scroll-btn-left.disabled {
	background-position: 0 0 !important;
}
.simply-scroll-btn-left:hover, .simply-scroll-btn-left:focus {
	background-position: 0 -88px;
}

.simply-scroll-btn-right {
	right: 6px;
	bottom: 6px;
	background-position: -84px -44px;
}
.simply-scroll-btn-right.disabled {
	background-position: -84px 0 !important;
}
.simply-scroll-btn-right:hover, .simply-scroll-btn-right:focus {
	background-position: -84px -88px;
}

.simply-scroll-btn-up {
	right: 6px;
	top: 6px;
	background-position: -126px -44px;
}
.simply-scroll-btn-up.disabled {
	background-position: -126px 0 !important;
}
.simply-scroll-btn-up:hover, .simply-scroll-btn-up:focus {
	background-position: -126px -88px;
}

.simply-scroll-btn-down {
	right: 6px;
	bottom: 6px;
	background-position: -42px -44px;
}
.simply-scroll-btn-down.disabled {
	background-position: -42px 0 !important;
}
.simply-scroll-btn-down:hover, .simply-scroll-btn-down:focus {
	background-position: -42px -88px;
}

.simply-scroll-btn-pause {
	right: 6px;
	bottom: 6px;
	background-position: -168px -44px;
}
.simply-scroll-btn-pause:hover, .simply-scroll-btn-pause:focus {
	background-position: -168px -88px;
}

.simply-scroll-btn-pause.active {
	background-position: -84px -44px;
}
.simply-scroll-btn-pause.active:hover, .simply-scroll-btn-pause.active:focus {
	background-position: -84px -88px;
}

/* Custom class modifications - override classees

.simply-scroll is default

*/

.simply-scroll .simply-scroll-list {}

.simply-scroll .simply-scroll-list li {
	float: left;
	width: 110px;
	text-align: center;
}
.simply-scroll .simply-scroll-list li img {}

.simply-scroll .simply-scroll-btn {}

.simply-scroll .simply-scroll-btn-left {}
.simply-scroll .simply-scroll-btn-left.disabled {}
.simply-scroll .simply-scroll-btn-left:hover {}

.simply-scroll .simply-scroll-btn-right {}
.simply-scroll .simply-scroll-btn-right.disabled {}
.simply-scroll .simply-scroll-btn-right:hover {}

.simply-scroll .simply-scroll-btn-up {}
.simply-scroll .simply-scroll-btn-up.disabled {}
.simply-scroll .simply-scroll-btn-up:hover {}

.simply-scroll .simply-scroll-btn-down {}
.simply-scroll .simply-scroll-btn-down.disabled {}
.simply-scroll .simply-scroll-btn-down:hover {}



/* Vertical scroller example */

.vert { /* wider than clip to position buttons to side */
width: 340px;
	height: 400px;
	margin-bottom: 1.5em;
}

.vert .simply-scroll-clip {
	width: 290px;
	height: 400px;
}

.vert .simply-scroll-list {}

.vert .simply-scroll-list li {
	width: 290px;
	height: 200px;
}
.vert .simply-scroll-list li img {}

.vert .simply-scroll-btn {}

.vert .simply-scroll-btn-up { /* modified btn pos */
	right: 0;
	top: 0;
}
.vert .simply-scroll-btn-up.disabled {}
.vert .simply-scroll-btn-up:hover {}

.vert .simply-scroll-btn-down { /* modified btn pos */
	right: 0;
	top: 52px;
}
.vert .simply-scroll-btn-down.disabled {}
.vert .simply-scroll-btn-down:hover {}

/* NOTE left-right classes wouldn't be needed on vertical scroller */



/********* #slideshow **********/
#slideshow{
	margin-bottom: 5px;
	position:relative;
}
.content_slide{
		width: 980px;
		margin: 0 auto;
		position: relative;
}
/**
 * #slideshow .bg_slideshow{
 * 	margin-left:-185px;
 * 	width:1350px;
 * }
 */
#slideshow ul{
	list-style: none;
	padding: 0;
	margin: 0;
}
#slideshow .wt-rotator{
	font-size:12px;
	background-color:#fff;
	position:relative;
	overflow:hidden;
	margin: 0 auto;
}
#slideshow .wt-rotator{
	line-height: 16px;
}
#slideshow .wt-rotator span.title{
	line-height: 25px;
	display: block;
	font-size: 15px;
	font-weight: bold;
}
#slideshow .wt-rotator a{
	outline:none;
}
#slideshow .wt-rotator .screen{
	position:relative;
	top:0;
	left:0;
	overflow:hidden;
}
#slideshow .wt-rotator #strip{
	display:block;
	position:absolute;
	top:0;
	left:0;
	z-index:0;
	overflow:hidden;
}
#slideshow .wt-rotator .content-box{
	display:none;
	position:absolute;
	top:0;
	left:0;
	overflow:hidden;
}
#slideshow .wt-rotator .main-img{
	display:none;
	position:absolute;
	top:0;
	left:0;
	z-index:0;
	border:0;
}
#slideshow .wt-rotator .preloader{
	position:absolute;
	top:50%;
	left:50%;
	width:36px;
	height:36px;
	margin-top:-18px;
	margin-left:-18px;
	border-radius:2px;
	background:#000 url(img/loader.gif) center no-repeat;
	background:rgba(0,0,0,.7) url(img/loader.gif) center no-repeat;
	z-index:4;
	display:none;
}
#slideshow .wt-rotator #timer{
	position:absolute;
	left:0;
	height:4px;
	background-color:#FFF;
	opacity:0.5;
	z-index:4;
	visibility:hidden;
	font-size:0;
}
#slideshow .wt-rotator .desc{
	color:#000;
	position:absolute;
	top:0;
	left:0;
	z-index:6;
	overflow:hidden;
	visibility:hidden;
	text-align:left;
}
#slideshow .wt-rotator .inner-bg{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:#000;
	opacity:0.7;
	z-index:1;
}
#slideshow .wt-rotator .inner-text{
	position:absolute;
	padding:10px;
	z-index:2;
}
#slideshow .wt-rotator .c-panel{
	position:absolute;
	top:0;
	z-index:7;
	visibility:hidden;
	display:none;
}
#slideshow .wt-rotator .outer-cp{
	position:absolute;
	left:0;
	width:100%;
	background:#696969;
	border:1px solid #000;
	border-left:none;
	border-right:none;
}
#slideshow .wt-rotator .thumbnails,
#slideshow .wt-rotator .buttons{
	display:inline;
	position:relative;
	float:left;
	overflow:hidden;
}
#slideshow .wt-rotator .thumbnails li,
#slideshow .wt-rotator .play-btn,
#slideshow .wt-rotator .prev-btn,
#slideshow .wt-rotator .next-btn{
	list-style:none;
	display:inline;
	float:left;
	margin-right:4px;
	overflow:hidden;
	width:24px;
	height:24px;
	line-height:24px;
	text-align:center;
	color:#FFF;
	background-color:#696969;
	cursor:pointer;
	font-weight:bold;
	border-radius: 10px;
}
#slideshow .wt-rotator .thumbnails li.thumb-over{
	color:#FFF;
	background-color:#888;
}
#slideshow .wt-rotator .thumbnails li.curr-thumb{
	color:#696969;
	background-color:#fff;
	cursor:default;
}
#slideshow .wt-rotator .thumbnails li.image{
	background-color:#000;
}
#slideshow .wt-rotator .thumbnails li.image.curr-thumb,
#slideshow .wt-rotator .thumbnails li.image.thumb-over{
	border-color:#06F;
}
#slideshow .wt-rotator .thumbnails li.image a,
#slideshow .wt-rotator .thumbnails li.image img{
	display:block;
	border:0;
}
#slideshow .wt-rotator .thumbnails li.image.curr-thumb img{
	opacity:0.8;
	cursor:default;
}
#slideshow .wt-rotator .thumbnails li *{
	display:none;
}
#slideshow .wt-rotator .thumbnails li div{
	position:relative;
	color:#FFF;
	background-color:#000;
}
#slideshow .wt-rotator .play-btn{
	background:#696969 url(img/play.png) no-repeat center;
}
#slideshow .wt-rotator .pause{
	background:#696969 url(img/pause.png) no-repeat center;
}
#slideshow .wt-rotator .prev-btn{
	background:#696969 url(img/prev.png) no-repeat center;
}
#slideshow .wt-rotator .next-btn{
	background:#696969 url(img/next.png) no-repeat center;
}
#slideshow .wt-rotator .button-over{
	background-color:#888;
}
#rotator-tooltip{
	position:absolute;
	top:0;
	left:0;
	z-index:99999;
	display:none;
}
#rotator-tooltip.txt-up{
	margin-left:-10px;
	margin-bottom:5px;
	background:url(img/tail.png) no-repeat;
	background-position:10px bottom;
}
#rotator-tooltip.txt-down{
	margin-left:-10px;
	margin-top:24px;
	background:url(img/rev_tail.png) no-repeat;
	background-position:5px top;
}
#rotator-tooltip.img-up{
	background:url(img/tail.png) center bottom no-repeat;
}
#rotator-tooltip.img-down{
	background:url(img/rev_tail.png) center top no-repeat;
}
#rotator-tooltip .tt-txt{
	font-size:12px;
	color:#FFF;
	background-color:#000;
	max-width:300px;
	padding:4px;
	margin:8px 0;
}
#rotator-tooltip .tt-img{
	display:none;
	background-color:#000;
	padding:2px;
	margin:3px 0;
}
#slideshow .wt-rotator .block,
#slideshow .wt-rotator .vpiece,
#slideshow .wt-rotator .hpiece{
	position:absolute;
	z-index:2;
}


/********************************************************/
/********************** #footer *************************/
/********************************************************/
#footer {
	background: url(img/bg_footer.jpg) no-repeat top center/136%;
/*  width: 100%;*/
	margin-top: 10px;
	margin: 0 auto;
}
#footer .box_footer{
	margin: 0px auto;
	position: relative;

}
#footer .top,#footer .bottom {
	position: absolute;
	width: 100%;
	height: 5px;
	background: transparent;
}

#footer .top {
	top: 0px;
}

#footer .bottom {
	bottom: 0px;
}

#footer .design{
	text-align: right;
	font-size: 11px;
	font-style: italic;
	position: absolute;
	bottom: 5px;
	right: 15px;
}
#footer .design span{
	color: #696969;
	font-weight: bold;
}
#footer .design span span{
	color: #F6D901;
}

/***** .text_link *****/
.text_link{
	padding: 10px 0 10px;
}

/***** .nav_top *****/
#footer .nav_top {
	text-align: center;
}

#footer .nav_top .left,#footer .nav_top .right,#footer .nav_top {
	height: 34px;
}

#footer .nav_top .left,#footer .nav_top .right {
	position: relative;
	top: 0;
	width: 5px;
}

#footer .nav_top .left {
	position: absolute;
	left: 0;
}

#footer .nav_top .right {
	position: absolute;
	right: 0;
}

#footer .nav_top ul {
/* 	background: #ddd; */
	padding: 8px;
}
#footer .nav_top ul ul{
	display: none;
}

#footer .nav_top ul li {
	display: inline;
	padding: 5px;
}

/**** .social ****/
#footer .social ul{
	list-style: none;
	padding: 0;
	margin: 0;
}
#footer .social{
	position: absolute;
		right: 0;
		bottom: 24px;
		padding: 0;
		list-style: none;
}
#footer .social li{
		float: left;
		margin-right: 5px;
}
#footer .social ul.link{
	margin-bottom: 5px;
}
#footer .social ul.link li{
	display: inline;
}


/**** .mesages_full ****/
.mesages_full{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(img/dot.png);
	z-index: 100000;
	padding-top: 200px;
}
.mesages_full div{
	width: 500px;
	margin: 0 auto;
	text-align: justify;
	background: #fff;
	font-size: 18px;
	padding: 15px;
	border-radius: 5px;
	line-height: 25px;
}
.mesages_full div span.title{
	display: block;
	margin-top: 10px;
}

/*** #skypedetectionswf ****/
#skypedetectionswf{
	position: absolute;
	bottom: 0;
	right: 0;
	width: 1px;
	height: 1px;
}


/**** #customize_tour_form ****/
#customize_tour_form{
	float: right;
	width: 100%;
	margin-bottom: 15px;
}
#customize_tour_form span.title{
	background: url(img/map_title.png) center top no-repeat;
	display: block;
	height: 63px;
}
#customize_tour_form .destination{
	background: #fdfaf2 url(img/map.jpg) center bottom no-repeat;
	border: 1px solid #ddd;
	padding: 10px;
	overflow: hidden;
	height: 730px;
}
#customize_tour_form .destination .title2{
	font-size: 14px;
	font-weight: bold;
	color: #076585;
	display: block;
}
#customize_tour_form .destination .title3{
	margin-top: 5px;
	font-size: 12px;
	font-weight: bold;
	display: block;
	font-style: italic;
}
#customize_tour_form .destination ul{
	margin: 0;
	padding: 0;
	list-style: none;
	float: left;
	margin-bottom: 10px;
}
#customize_tour_form .destination ul li{
	display: block;
	float: left;
	width: 202px;
	font-size: 12px;
}
#customize_tour_form .form2 #tab_contain{
	background: #c0e8f6;
	border: 2px solid #076585;
	padding: 15px 10px;
}
#customize_tour_form .form2 #tab_contain table{
	width: 100%;
}
#customize_tour_form .form2 table th,
#customize_tour_form .form2 table td{
	padding: 3px 0;
	text-align: left;
}
#customize_tour_form .form2 table tr.age label{
	display: block;
	float: left;
	width: 112px;
	font-size: 12px;
}
#customize_tour_form .form2 table tr.age input.small{
	margin-bottom: 5px;
	width: 44px;
}
#customize_tour_form .form2 th{
	font-size: 12px;
	width: 168px;
}
#customize_tour_form .form2 th span{
	font-size: 11px;
	font-style: italic;
	display: block;
	font-weight: normal;
	line-height: 12px;
}
#customize_tour_form .form2 td span.im{
	margin-left: 5px;
}
#customize_tour_form .form2 select,
#customize_tour_form .form2 input,
#customize_tour_form .form2 textarea{
	border: 1px solid #55a9c6;
}
#customize_tour_form .form2 select.gender{
	width: 50px;
	margin-right: 2px;
}
#customize_tour_form .form2 input.name{
	width: 148px;
}
#customize_tour_form .form2 select.larger{
	width: 212px;
}
#customize_tour_form .form2 select.date{
	width: 68px;
}
#customize_tour_form .form2 input.larger{
	width: 200px;
}
#customize_tour_form .form2 select.small{
	width: 56px;
}
#customize_tour_form .form2 textarea.larger{
	width: 200px;
	padding: 5px;
}
#customize_tour_form .form2 #OrderTourChildren{
	margin-left: 22px;
}

#customize_tour_form .form2 ul.tab_customize{
	margin: 12px 0 -2px;
	padding: 0;
	list-style: none;
	overflow: hidden;
	background: #c5c5c5;
}
#customize_tour_form .form2 ul.tab_customize li{
	float: left;
	width: 50%;
	display: block;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 12px;
	font-weight: bold;
}
#customize_tour_form .form2 ul.tab_customize li a{
	color: #696969;
}
#customize_tour_form .form2 ul.tab_customize li a:hover{
	text-decoration: none;
}
#customize_tour_form .form2 ul.tab_customize li a.current{
	background: #c0e8f6;
	border: 2px solid #076585;
	border-bottom: none;
	display: block;
}
#customize_tour_form .form2 .tab_content{
	display: none;
}
#customize_tour_form .form2 #tab1{
	display: block;
}
#customize_tour_form .form2 tr.date{
	display: none;
}
#customize_tour_form .form2 tr.age,
#customize_tour_form .form2 tr.age p{
	display: none;
}

#customize_tour_form .form2 tr.submit button{
	width: 107px;
	height: 31px;
	width:135px;
	border:none;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	color: #696969;
}
#customize_tour_form .form2 tr.submit button:hover{
	cursor: pointer;
}
#customize_tour_form .form2 tr.submit button.next{
	background: url(img/btn_next.jpg) no-repeat;
}
#customize_tour_form .form2 tr.submit button.back{
	background: url(img/btn_back.jpg) no-repeat;
}
#customize_tour_form .form2 tr.submit button.submit{
	background: url(img/btn_send.jpg) no-repeat;
}



/********.tripplan*********/
.tripplan #main{
	background:white;
	margin-top:10px;
}
.tripplan #content .box_booking{
	padding:10px 0;
	margin-bottom:15px;
	color:#696969;
}
.tripplan #content span.title{
	font-size:14px;
	font-weight:bold;
	line-height:25px;
	color:#696969;
}
.tripplan #content table{
	width:100%;
	color:#696969;
}
.tripplan #content table,
.tripplan #content table td,
.tripplan #content table th{
	border:none!important;

}
.tripplan #content table td,
.tripplan #content table th{
	padding:3px;
	text-align:left;
	font-size:12px;
}

.tripplan #content table input{
	padding: 3px;
	float: left;
}
.tripplan #content label {
		margin-left: 5px;
		margin-top: 5px;
}
.tripplan #content table input#TripplanName {
		float: none;
}
.tripplan #content table select{
	padding: 3px;
}
.tripplan #content input.small{
	width: 100px;
}
.tripplan #content input.medium{
	width: 200px;
}
.tripplan #content input.larger{
	width: 300px;
}
.tripplan #content input.larger-medium{
	width: 238px;
	margin-left: 2px;
}
.tripplan #content select.larger{
	width: 306px;
}
.tripplan #content select.medium{
	width: 205px;
}
.tripplan #content select.small-medium{
	width: 108px;
}
.tripplan #content select.small{
	width: 60px;
}
.tripplan #content textarea.larger{
	width: 696px;
	height: 70px;
}
.tripplan #content .submit{
	margin-left: 30%;
	margin-top:10px;
}
.tripplan #content .submit input{
	background: url("img/button.png") no-repeat scroll -202px -66px;
		border: medium none;
		color: #FFFFFF;
		font-weight: bold;
		height: 28px;
		width: 111px;

}
.tripplan #content .submit input:hover{
	background-position: -202px -94px;
		cursor: pointer
}

.tripplan #content table.booking1 th{
	width:160px;
}
.tripplan #content table.booking2 th{
	text-align:right;
}
.tripplan #content table.booking3 th,
.tripplan #content table.booking5 th{
	width:160px;
}
.tripplan #content table.booking3 input{
	margin-left:0;
}
.tripplan #content table.booking6 td{
	width:25%;
}
.tripplan #content ul.destination{
	width:100%;
	margin:0;
	padding:0;
	overflow:hidden;
}
.tripplan #content ul.destination li{
	float:left;
	width:169px;
	padding:2px 0;
	list-style:none;
}


/******************** box_content Testimonians *******************/
#content .box_content #testimonial{
	overflow: hidden;
		background: #fff;
		padding: 10px;
}
#testimonial .box-tes {
		display: inline-block;
		margin-bottom: 10px;
		border-bottom: 1px dashed #ccc;
}
#content .box_content.box_testimonial #testimonial .title{
	display: inline;
}
#testimonial .box-tes .thumb {
		float: left;
		margin-right: 10px;
}
#content .box_content .box_testimonial{
	margin-bottom: 8px;
}
#content .box_content .box_testimonial .title{
/* 	background: #E5E5E5; */
	padding: 4px 0 6px;
	font-weight: bold;
	font-size: 13px;
	color: #696969;
	height:18px;
}
#content .box_content .box_testimonial .title a{
	float:left;
}
#content .box_content .box_testimonial p.description{
	padding-bottom: 10px;
}
#content .box_content .box_testimonial p.about{
	text-align: right;
	text-decoration: underline;
	font-size: 0.8em;
}



/******* #tab-top *******/
#tab-top{
	margin-top: -50px;
	z-index: 100;
	position: relative;
	margin-bottom: 10px;
	float: left;
	/**
 * width: 960px;
 * 	padding: 10px;
 * 	background: #fff;
 */
/**
 * 	overflow: hidden;
 */
}
#tab-top .des{
	text-align: justify;
		width: 102%;

}
/******#tab-top .product*******/
#tab-top .box_post{
	float: left;
		height: 125px;
		margin: 5px;
		position: relative;
		width: 303px;
	min-height:400px;
}
#tab-top .service{
	overflow: hidden;
	margin: 0;
		padding-bottom: 10px;
}

#tab-top .service article {
		background: none repeat scroll 0 0 #fff;
		float: left;
		height: 262px;
		padding: 10px;
		position: relative;
		text-align: justify;
		width: 307px;

}
#tab-top .service article h2{
	font-size:16px;
	text-align:left;
}
#tab-top .service article h2 a{
	color:#2980b9;
}
#tab-top .service article.box_2{
	margin-right: 0;
}
#tab-top .service article img{
	border-radius: 3px;
}
#tab-top .service article img:hover{
	opacity: 0.8;
}
#tab-top .service article header{
	margin-bottom: 6px;
}
#tab-top .service article header a:hover{
	color: #F6D901;
	text-decoration: none;
}
#tab-top .service .btn-details{
		border-radius: 10px;
		color: #fff !important;
		display: inline-block;
		font-weight: normal;
		height: 19px;
		line-height: 18px;
		padding: 0 5px 0 8px;
		position: absolute;
		width: auto;
	float:right;
	margin-top:5px;
		bottom: 15px;
		right: 18px;
	padding-right:22px;
	background:#5d9ce9 url(img/arrow6.png) no-repeat 78px 5px;
}
#tab-top .service .btn-details:hover{
	background:#2980b9 url(img/arrow6.png) no-repeat 78px 5px;
	text-decoration:none;
}

#pre-footer-inner {
/*     background: linear-gradient(#d8d8df, #c5c9cf) repeat scroll 0 0; */
		border-radius: 0 0 8px 8px;
		margin: auto;
		padding: 0;
		width: 100%;
}
.container:before, .container:after {
		content: "";
		display: table;
}

.row:before, .row:after {
		content: "";
		display: table;
}
.span6 {
		width: 32.6%;
	float:left;
	height: 195px;
	margin-right:10px;
}
.span6.box_3{
	margin-right:0px;
}

.panel-promo {
		background: linear-gradient(#fdfdfe, #e5e8ea) repeat scroll 0 0;
		box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.3);
		height: 196px;
		overflow: hidden;
		position: relative;
}
.span6 .panel {
		background: #05274d;
		border: 1px solid #ddd;
		border-radius: 4px;
/*     margin: 16px 0; */
		padding: 16px;
}


.panel-promo.bg1 .bg {
		background: url("img/panel-promo-bg1.png") no-repeat scroll left center;
}
.panel-promo .bg {
		height: 190px;
		left: 0;
		position: absolute;
		top: 0;
		width: 127px;
		z-index: 100;
}

.panel-promo.bg2 .bg {
		background: url("img/panel-promo-bg2.png") no-repeat;
		height: 154px;
		left: 50%;
		margin-left: -127px;
		margin-top: 12px;
		position: absolute;
		width: 254px;
		z-index: 1000;
}
.panel-promo .body {
		padding-left: 94px;
		color: #fff;
}

.panel-promo.bg2 .body {
		margin-top: 82px;
		padding: 0;
}


.panel-promo h3 {
		font-size: 15px;
		margin-bottom: 5px;
		margin-top: 0;
}
.panel-promo .author{
	text-align:right;
}
.quote-gallery {
		height: 100px;
		overflow: hidden;
		position: relative;
		width: 190px;
}
.panel-promo .action {
		position: relative;
		float: left;
		width: 100%;
	margin-top: 10px;
}
.btn-flat, .btn-flat:active, .btn-flat:visited {
		background: linear-gradient(to bottom, #60a5d3, #05274d) repeat-x scroll 0 0 #7c291b;;
}
.btn-details, .btn-details:active, .btn-details:visited {
		border-radius: 10px;
		color: #fff !important;
		display: inline-block;
		font-weight: normal;
		height: 19px;
		line-height: 18px;
		padding: 0 13px;
		position: relative;
		width: auto;
}

.panel-promo.bg2 {
		overflow: visible;
}
.panel-promo.bg2 ul {
		padding: 0;
	margin:5px 0!important;
}
.panel-promo.bg2 ul li{
	background: url("img/arrow5.png") no-repeat scroll left 4px;
		padding: 3px 0 3px 20px;
	list-style:none;
}

.panel-promo.bg3 .bg {
		background: url("img/panel-promo-bg3.png") no-repeat scroll left center;
}

.panel-promo.bg3 #newsletter #NewsletterEmail{
		border: 0 none;
		border-radius: 15px 0 0 15px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4) inset;
		height: 30px;
		line-height: 30px;
		margin: 0;
		padding: 0 5px 0 12px;
		color: #333;
		width: 123px;
	display:block;
	float:left;
}
.panel-promo.bg3 #newsletter .submit{
		background: linear-gradient(to bottom, #60a5d3, #05274d) repeat-x scroll 0 0 #7c291b;
		border: 0 none;
		border-radius: 0 15px 15px 0;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3) inset;
		color: #fff;
		cursor: pointer;
		font-size: 13px;
		font-style: italic;
		height: 30px;
		margin: 0 0 0 -4px;
		padding: 1px 15px 2px 10px;
		width: 62px;
}

/***** .search-tour *****/
.search-tour{
	position: absolute;
	bottom: 141px;
	left: 60px;
	z-index: 100;
	background: url(img/bg-search.png);
	padding: 20px 16px;
	width:800px;
}

.search-tour .des{
	text-align: justify;
}
.search-tour .bg-left,
.search-tour .bg-right{
	position: absolute;
	top: 33px;
	width: 7px;
	height: 147px;
}
.search-tour .bg-left{
	background: url(img/tab-top-bg-left.png) left top no-repeat;
	left: -7px;
}
.search-tour .bg-right{
		background: url(img/tab-top-bg-right.png) right top no-repeat;
	right: -7px;
}
.search-tour ul.tabs{
	overflow: hidden;
	margin: 0;
	padding: 14px 0 0 0!important;
	height:32px;
}
.search-tour ul.tabs li{
	float: left;
	height: 33px;
	line-height: 33px;
	list-style: none;
	text-align: center;
	background-color: white;
	font-weight: bold;
	margin-right: 5px;
	border-radius:20px;
	opacity:0.8;
		position: absolute;
		padding: 0 15px;
}
.search-tour ul.tabs li .arrow_s{
		background: url("img/arrow_s.png") no-repeat;
		display: none;
		height: 20px;
		left: 40%;
		position: absolute;
		top: -10px;
		width: 20px;
		z-index: 99;
}
.more > a {
	border-radius: 12px;
	color: #fff;
	padding: 2px 26px 3px 9px;
	background: url("img/arrow6.png") no-repeat scroll 82px 6px #04274c;
	color: #fff!important;
}
.more > a:hover{
		background: url("img/arrow6.png") no-repeat scroll 82px 6px #064182;
}
.search-tour ul.tabs li.active{
	background-color: #F25F2B;
	opacity:initial;
}
.search-tour ul.tabs li.active .arrow_s{
	display:block;
}
.search-tour ul.tabs li.active a{
	color: white;
}
.search-tour ul.tabs li a{
	color: #777;
	display: block;
	text-transform: uppercase;
}
.search-tour ul.tabs li a:hover{
	text-decoration: none;
}

.search-tour .tab_container{
/* 	padding: 10px 12px 20px 12px; */
	background: url(img/bgs.png) repeat-x;
	overflow: hidden;
	margin-top:0px;
	border-radius:3px;
	height:73px;
		border-radius: 35px;

}
#search_input {
		position: relative;
		right: 1500px;
}
.search-tour .tab_container div{
	background: #fff;
		height: 54px;
		margin: 10px auto;
		width: 777px;
		border-radius: 35px;
}
.search-tour .tab_container input.text_b{
	width:558px;
	height:54px;
	border:none;
	border-radius:0;
	padding:0 15px;
	font-size:20px;
	color:#808080;
		border-radius: 35px;
	display:block;
	float:left;
}
.search-tour .tab_container input.sub {
		background: none repeat scroll 0 0 #f15b26;
		bottom: -8px;
		color: #fff;
		cursor: pointer;
		float: left;
		font-size: 12px;
		font-weight: bold;
		height: 55px;
		line-height: 37px;
		margin-left: 6px;
		margin-top: 0;
		text-transform: uppercase;
		width: 183px;
		border-radius: 0 35px 35px 0;
		border: none;
}
.search-tour .tab_container input.sub:hover{
	background: #E03B04;
}

/***** .booking_form *****/
.booking_form{
	background: #fff;
	padding: 0 20px;
}
.booking_form table,
.booking_form table th,
.booking_form table td{
	border:none!important;
}
.booking_form p{
	text-align: center;
}
.booking_form .headline h2{
	text-align: center;
}
.booking_form span.title{
	color:#F27D30;
	display: block;
	padding:0 0 5px 0;
}
.booking_form .box1 span.title2,.booking_form .box2 span.title2,.booking_form .box3 span.title2{
		font-size: 15px;
		line-height: 22px;
		font-weight: bold;
		color:#F27D30;
	display: block;
	padding:15px 0 5px 0;
}
.booking_form .tab_content_1{
	border: 1px solid #DDDDDD;
}
.booking_form .tab_content_1{
	background:#F8F8F8;
	padding:10px;
}
.booking_form .tab_content_2{
	background:#F8F8F8;
	padding:10px;
		border: 1px solid #ddd;
}
.booking_form .tab_content_3{
	background:#F8F8F8;
	padding:10px;
}
.booking_form th,
.booking_form td{
	padding: 3px 0;
}
.booking_form table th{
	width: 213px;
	text-align:left;
}
.booking_form input.larger{
	width: 250px;
}
.booking_form input.larger-name{
	width: 180px;
}
.booking_form input.medium{
	width: 173px;
}
.booking_form tr.available{
	display: none;
}
.booking_form tr.available label{
	display: block;
	float: left;
	width: 100px;
}
.booking_form tr.available input{
	margin-bottom: 3px;
}
#content .booking_form tr.available td{
	padding-bottom: 10px;
}
#content .booking_form textarea.larger{
	width: 276px;
}
#content .booking_form select.small{
	width: 70px;
}
#content .booking_form select.medium{
	width: 262px;
}
#content .booking_form select.larger{
	width: 286px;
}
#content .booking_form tr.name td{
	font-weight: bold;
	color: #F27D30;
}
#content .booking_form tr.group select.small{
	width: 52px;
	margin-left: 2px;
}
#content .booking_form select.adult,
#content .booking_form select.children{
	margin-right: 8px;
}
#content .booking_form .submit{
	margin-top: 20px;
	margin-bottom: 10px;
	text-align: center;
}
#content .booking_form .submit input{
		padding-right: 33px;
	background: url("img/button.png") no-repeat scroll 0 -196px;
	border: none;
	font-weight: bold;
	text-transform: uppercase;
	color: #fff;
	width: 121px;
	height: 34px;
}
#content .booking_form .submit input:hover{
	background-position: 0 -230px;
	cursor: pointer;
}
/*** order info**/
.box_act td {
		float: left;
}
.box_act td {
		float: left;
		padding: 0 12px !important;
		width: 170px;
}
.review .tab_content th {
		text-align: left;
}
.tab_content .title {
		font-size: 1.5em;
		font-weight: bold;
}

#content .product .title .title_right .title_center span {
		color: #05274d;
		font-family: tradegothicltbold20;
		font-size: 21px;
	border-bottom: 2px solid #c10d0e;
		text-transform: uppercase;
}

.label {
		cursor: pointer;
		width: 15%;
}
#desc_day ul {
		list-style: none outside none;
		margin: 0 !important;
}
#desc_day ul li{
		list-style: disc outside url('img/check.gif');
}
#re_password {
		margin-left: 0;
}

#google_translate_element{
	position:absolute;
	top:55px;
	right:22px;
}
#map_view > div {
		float: left;
}
.error {
		color: red;
		margin-left: 10px;
		font-style: italic;
}
#content ul.post_d{
		float: left;
		width:  100%;
}
#tab_post {
		display: inline-block;
		margin-top: 10px;
}
#content .box_content .destination_tour .info .ribbon {
		height: 142px;
}

/****** .search_tour *****/
.search_tour{
	position: absolute;
	bottom: 88px;
	right: 0px;
	z-index: 10;
	width: 300px;
	padding: 15px;
	background: url(img/bg_search.png);
	color: #eee;
	text-align: center;
}
.search_tour span.title{
	font-size: 15px;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 25px;
	margin-bottom: 20px;
	display: block;
	text-align: center;
	text-shadow: 1px 1px 3px #000;
}
.search_tour p{
	margin-top: 10px;
	text-align: left;
	font-weight: bold;
	margin-bottom: 10px;
}
.search_tour select{
	width: 270px;
	padding: 5px 4px;
	border: none;
	margin-bottom: 10px;
}
.search_tour input#duration{
	background: transparent;
	border: none;
	color: #fff;
	width: 74px;
	text-align: right;
}
.search_tour #duration_range{
	margin-bottom: 10px;
}
.search_tour input.submit {
		background: url("img/button.png") no-repeat scroll -142px -183px;
		border: medium none;
		color: #fff;
		font-size: 16px;
		font-weight: bold;
		height: 52px;
		margin-top: 7px;
		text-transform: uppercase;
		width: 232px;
}
.search_tour input.submit:hover{
	cursor: pointer;
	background-position: -142px -235px;
}
.search_tour input#FilterProductName,.search_tour input#FilterPostName {
		height: 27px;
		padding: 0 5px;
		display: inline-block;
		margin-bottom: 10px;
		width: 95%;
}

.support .yahoo,.support .skype {
		float: left;
		margin-right: 10px;
}
.box_footer .support li .sup_acc {
		float: left;
		width: 100%;
}
#h_support > label {
		color: #0065ab;
		float: left;
		font-size: 15px;
		font-weight: bold;
		height: 25px;
		text-transform: uppercase;
		width: 100%;
}
#h_support #phone {
	color: #c10d0e;
	font-size: 1.05em;
	font-family: FuturaBkBT;
}
#h_support td {
		font-family: FuturaBkBT;
		font-size: 14px;
		font-weight: bold;
		height: 22px;
		line-height: 16px;
}
#h_support #email {
	color: #c10d0e;
	font-family: FuturaBkBT;
}
#h_support a {
		display: inline-block;
		padding: 3px 0;
}
.tailormade {
		background: url(img/bg_tripplan.png) no-repeat center center;
		height: 238px;
		position: fixed;
		right: 0;
		top: 190px;
		width: 37px;
		z-index: 10;
}
.tailormade span{
		color: #fff;
}
/* .thumb_promotion { */
/*     float: left; */
/*     padding: 10px; */
/* } */
/* #content .box_content .box_product.promotion a { */
/*     font-family: tradegothiclt18; */
/*     font-size: 21px; */
/* } */
/* #content .box_content .des_promotion{ */
/*     background: #fff; */
/* } */
/* .des_promotion .list_promotion { */
/*     float: right; */
/*     width: 430px; */
/*     margin: 10px 0; */
/* } */
/* .des_promotion .list_promotion .promotion{ */
/*     line-height: 20px; */
/*     background: url(img/dot1.png) no-repeat left center; */
/*     padding-left: 15px; */
/* } */
#content .des_promotion .list_promotion .promotion .name a {
/*     color: #696969; */
/*     font-family: tradegothiclt18; */
/*     font-size: 18px; */
}

.home_gallery .show {
		display: inline-block;
		list-style: outside none none;
		padding: 5px 6px;
		overflow: hidden;
	height: 92%;
		margin-top: 4px;
}
.span6 .des{
	height: 100%
}
.span6 .home_gallery {
	height: 100%;
	background: #05274d;
	border-radius: 3px;
	border: 1px solid #ccc;
}
.home_gallery li {
		float: left;
		margin: 0 4px 4px 0;
}
.search_tour select:hover {
		cursor: pointer;
}
#FilterHomeForm > ul#search_product {
		color: #333;
}
#content .box_content.box_testimonial #testimonial .title a {
		float: left;
}
.panel-body .cart {
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
}
#content input#recaptcha_response_field {
	height: 23px;
}
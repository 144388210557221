@media (min-width: $tablet + 1px) {
	.container {
		width: 1080px;
	}
}

.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: #f0f0f0;
}

.dropdown-menu {
	min-width: 200px;
	margin: 0;
}

.form-control {
	&:focus {
		box-shadow: none;
		border-color: $primary-color;
	}
}

.btn-default {
	background: $primary-color !important;
	border: none;
	color: white !important;
	text-shadow: none;
	outline: none !important;

	&:hover,
	&:active,
	&:focus {
		background: darken($primary-color, 5) !important;
		border: none;
		color: white;
		text-shadow: none;
	}
}

@media (min-width:1200px){
	.auto-clear .col-lg-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-lg-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-lg-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-lg-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-lg-6:nth-child(odd){clear:left;}
}
@media (min-width:992px) and (max-width:1199px){
	.auto-clear .col-md-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-md-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-md-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-md-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-md-6:nth-child(odd){clear:left;}
}
@media (min-width:768px) and (max-width:991px){
	.auto-clear .col-sm-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-sm-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-sm-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-sm-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-sm-6:nth-child(odd){clear:left;}
}
@media (max-width:767px){
	.auto-clear .col-xs-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-xs-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-xs-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-xs-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-xs-6:nth-child(odd){clear:left;}
}
.related {
	float: left;
	width: 100%;

	.title {
		font-weight: bold;
		margin: 0 0 5px 0;
		font-size: 15px;
		padding: 10px 0 0 0;
	}

	#show_post_related {
		float: left;

		ul {
			float: left;
			padding: 0;
			margin: 10px 0;
			list-style: none;

			li {
				padding: 0 0 0 17px;
				background: url('img/icon.png') -254px -89px no-repeat;
				margin-bottom: 2px;
			}
		}
	}
}

.post-info-content {
	div,
	img {
		@media screen and (max-width: $tablet) {
			max-width: 100%;
			height: auto !important;
		}
	}
}

.detail_post {
	.description {
		float: left;
		width: 100%;
	}

	#tab-selector {
		margin-top: 15px !important;
		clear: both;
	}

	ul.tabs li {
		background-color: white !important;
		color: $text-color !important;

		&.active {
			border-color: $primary-color !important;
			border-bottom: none !important;
		}

		a {
			color: $text-color !important;
		}
	}

	ul.tabs li a:hover {
		background-color: white !important;
		color: $text-color !important;
	}

	.tab-product-des {
		display: block !important;
	}

	.tab_container {
		width: 100% !important;
		border: none !important;

		.tab_content {
			padding: 10px 0 0 0;
			float: left;
			width: 100%;

			img {
				max-width: 100%;
				height: auto !important;
			}

			&#tab_comment {
				.embed-responsive-16by9 {
					padding-bottom: 25%;
				}

			}
		}
	}

	#comments-gp {
		width: 100% !important;
		height: auto !important;

		iframe {
			width: 100% !important;
		}
	}

	.facebook_comment_icon {
		width: 15px;
		vertical-align: top;
		margin-top: 5px;
	}

	.google_comment_icon {
		width: 15px;
		vertical-align: top;
		margin-top: 5px;
	}
}


.c_post {
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
		border-bottom: none;
	}

	.thumb {
		padding: 0;
	}

	.title {
		border-bottom: 2px solid $primary-color;
		line-height: 35px;
		height: 35px;
		margin-bottom: 10px;
	}

	.name {
		margin-bottom: 5px;
		font-weight: bold;
		font-size: 14px;
	}

	.datetime {
		margin: 0 0 5px 0;

		.glyphicon {
			font-size: 12px;
			margin-right: 3px;
		}
	}

	&.onecol {
		border-bottom: 1px solid $divider-color;
		padding-bottom: 10px;
		margin-bottom: 10px;

		&:last-child {
			border-bottom: none;
		}

		.name {
			font-weight: normal;
			font-size: 22px;
		}
	}
}
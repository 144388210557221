#slideshow-wrapper {
	float: left;
	width: 100%;

	@media screen and (max-width: $tablet) {
		display: none;
	}

	.search_tour {
		display: none;
	}

	#slideshow {
		margin: 0;
	}

	.lSSlideOuter {
		position: relative;

		.lslide {
			text-align: center;

			img {
				display: inline-block !important;
			}
		}

		.lSPager {
			margin-top: -25px !important;

			> li {
				a {
					background-color: white !important;
				}
			}

			> li.hover a,
			> li.active a {
				background-color: $second-color !important;
			}
		}
	}

	#search_product {
		padding: 0;
		margin: 0;
	}
}


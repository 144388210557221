aside.box {
	border: 1px solid $divider-color;
	float: left;
	margin-bottom: 20px;
	width: 100%;

	.title {
		border: none;
		background-color: $primary-color;
		color: white;
		padding: 0 15px;
		box-sizing: border-box;
	}

	&.support {
		.supporters {
			list-style: none;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0 10px;

			> li {
				float: left;
				width: 100%;
				border-bottom: 1px solid #ddd;
				padding-bottom: 5px;
				margin-bottom: 5px;

				&:last-child {
					border-bottom: 0;
				}

				.supporter {
					list-style: none;
					float: left;
					width: 100%;
					margin: 0;
					padding: 0;

					li {
						float: left;
						width: 100%;

						&.name {
							font-weight: bold;
						}

						&.phone {
							color: red;
						}

						&.email {
							color: green;
						}

						.glyphicon {
							margin-right: 10px;
							color: black;
						}
					}
				}
			}
		}

		.social {
			text-align: center;
			list-style: none;
			float: left;
			width: 100%;
			margin: 10px 0 0 0;
			padding: 0;

			li {
				display: inline-block;
				height: 27px;
				width: 27px;
				margin: 0;

				&.facebook {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -7px -5px;
					background-size: 154px;
				}

				&.k {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -34px -5px;
					background-size: 154px;
				}

				&.gmail {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -62px -5px;
					background-size: 154px;
				}

				&.gplus {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -91px -5px;
					background-size: 154px;
				}
			}
		}
	}

	&.s_post {
		.post-thumb-list {
			float: left;
			width: 100%;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				background: white;
				width: 100%;
				float: left;
				padding: 5px;
				padding-left: 5px;
				border-bottom: 1px solid #efefef;
				position: relative;

				a {
					width: 100%;
					float: left;
					height: 100%;
					color: $text-color;

					.thumb {
						float: left;

						img {
							margin-right:: 10px;
						}
					}

					.name {
						float: left;
						width: calc(100% - 90px);
					}
				}
			}
		}
	}

	&.likebox {
		.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {
			width: 100% !important;
		}
	}

	&.adv_left_out {
		ul {
			list-style: none;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0;
		}
	}

	&.category {
		border: 1px solid #cecece;

		#tree {
			> li {
				padding: 0;
			}
		}
	}

	&.gallery {
		box-shadow: 0px 0px 5px #c3c3c3;
		border: 1px solid #cecece;

		#tree {
			li {
				&.parent {
					padding: 0;

					> a {
						padding: 0px 0 0 35px;

						&.photo {
							background: url('img/gallery-photo-icon.png') 8px 6px no-repeat;
							background-size: 18px;
						}

						&.video {
							background: url('img/gallery-video-icon.png') 8px 6px no-repeat;
							background-size: 18px;
						}
					}

					> ul {
						padding-left: 0 !important;

						> li {
							list-style-type: none !important;
							padding-left: 0 !important;

							> a {
								width: 100%;
								float: right;
								padding: 0 0 0 15px;
								text-transform: none;
							}
						}
					}
				}

				ul {
					display: block;

					li {
						list-style: none;
						padding-left: 0;

						a {
							width: 100%;
							float: right;
							padding: 0 0 0 15px;

							.glyphicon {
								font-size: 10px;
								margin: 0 10px 0 0;
							}
						}
					}
				}
			}
		}
	}
}

.block_title {
	border: none;

	h2 {
		background-color: $primary-color;
		color: white;
		padding: 0 15px;
		box-sizing: border-box;

		.glyphicon {
			margin-right: 10px;
		}
	}
}

#tree {
	list-style: none;
	float: left;
	width: 100%;
	margin: 0;
	padding: 0;

	.category-icon {
		margin: 0 10px 3px 0;
	}

	.caret-wrapper {
		float: right;
		padding: 2px 10px;
		cursor: pointer;
		display: none;
	}

	li {
		float: left;
		width: 100%;
		line-height: 30px;
		padding: 0 10px;
		border-bottom: 1px solid $divider-color;

		&.parent {
			> a {
				text-transform: uppercase;
				width: 100%;
				margin: 0;
				padding: 0 0 0 10px;
				display: inline-block;
				letter-spacing: 0.5px;
				word-spacing: 1px;
				font-weight: bold;
			}
		}

		&.current {
			> ul {
				display: block
			}
		}

		&.child {
			padding: 0 10px 0 27px;
			list-style-type: circle;
			list-style-position: inside;
		}

		&.current.parent {
			> a {
				background-color: $third-color;
				color: white;

				&:hover,
				&:active,
				&:focus {
					background-color: $third-color;
				}
			}
		}

		&.current:not(.parent) {
			> a {
				background-color: $second-color;
				color: white;

				&:hover,
				&:active,
				&:focus {
					background-color: $second-color;
				}
			}
		}

		&:last-child {
			border-bottom: none;
		}

		a {
			color: $text-color;
			font-size: 13px;
		}

		> ul {
			display: none;
			position: static;
			margin: 0;
			padding: 0;
			width: 100%;
			float: left;
			box-shadow: none;
			border-left: none;
			border-right: none;
			border-bottom: none;
			border-top: 1px solid $primary-color;
			border-radius: 0;
			min-width: initial;

			li {
				margin: 0;
				padding: 0;
				width: 100%;
				float: left;

				a {
					padding: 6px 0px 6px 35px;
					word-spacing: 2px;
					font-weight: bold;

					&:hover,
					&:focus {
						background-color: white;
						background-image: none;
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.tree {
	> ul {
		float: left;
		width: 100%;
		margin: 0;
		padding: 0;

		> li {
			border-bottom: 1px solid $divider-color;
			list-style-type: none !important;
		}

		ul {
			float: left;
			width: 100%;
			margin: 0;
			padding: 0;
		}

		li {
			float: left;
			width: 100%;
			line-height: 28px;
			padding: 2px 10px;
			list-style-type: circle;
			list-style-position: inside;

			&:last-child {
				border-bottom: none;
			}

			a {
				text-transform: uppercase;
				color: $text-color;
				font-size: 13px;
			}
		}
	}
}
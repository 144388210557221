.c-hotel {
	.thumb {
		float: left;
		width: 150px;

		@media screen and (max-width: $tablet) {
			width: 100%;
		}

		a {
			img {
				height: auto;
			}
		}
	}

	.name.desktop {
		text-align: left;
		padding: 7px 10px;

		@media screen and (max-width: $tablet) {
			display: none;
		}
	}

	.info {
		width: calc(100% - 150px);

		@media screen and (max-width: $tablet) {
			width: 100%;
		}

		.name {
			@media screen and (max-width: $tablet) {
				text-align: center;
			}

			&.mobile {
				@media screen and (min-width: $tablet + 1px) {
					display: none;
				}
			}

		}

		.price {
			font-weight: bold;
			color: red;

			.new {
				font-weight: bold;
				color: red;
			}
		}

		.ribbon {
			@media screen and (min-width: $tablet + 1px) {
				height: 28px;
			}

			.cart {
				a {
					@media screen and (min-width: $tablet + 1px) {
						float: right;
					}
				}
			}
		}

		.summary {
			margin: 10px 0;
		}
	}
}

.detail_hotel {
	.des {
		.info_top {
			.top_view {
				margin-bottom: 10px;
			}

			.info {
				width: calc(100% - 375px);
				margin-left: 15px;

				@media screen and (max-width: $tablet) {
					width: 100%;
					margin: 0;
				}

				.star {
					margin: 0;
				}
			}
		}

		#hotel_picture {
			.allshow {
				margin-bottom: 15px;
			}
		}
	}
}
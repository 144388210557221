$primary-color: #1a2b4f;
$second-color: #a60e00;
$third-color: #2980b9;
$divider-color: #ddd;
$text-color: #333;
$right-boxes-space: 30px;
$mobile: 576px;
$tablet: 768px;

* {
	outline: none;
	font-family: Arial, sans-serif;
}

@import 'old-frontend';
@import 'override_bootstrap';
@import 'flash_message';
@import 'global';
@import 'nav';
@import 'header';
@import 'footer';
@import 'home';
@import 'product';
@import 'contact';
@import 'category';
@import 'post';
@import 'counter';
@import 'comment';
@import 'information';
@import 'smooth_product';
@import 'sidebar';
@import 'slideshow';
@import 'breadcrumb';
@import 'gallery';
@import 'video';
@import 'document';
@import 'hotel';
@import 'booking';

#nav-wrapper {
	background-color: $primary-color;
	float: left;
	width: 100%;

	&.stick {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 10000;
	}

	.navbar {
		border: none;
		min-height: 0;
		margin-bottom: 0;
		border-radius: 0;

		@media screen and (max-width: $mobile) {
			margin-top: 0;
		}

		.icon_home {
			background-image: url('img/home.png');
			background-repeat: no-repeat;
			background-position: 0 0;
			width: 20px;
			height: 20px;
			display: block;
			margin: 3px 0;

			@media screen and (max-width: $tablet) {
				display: inline-block;
			}
		}

		.navbar-toggle {
			margin: 0 5px 0 0;
		}

		.icon-bar {
			background-color: white;
		}

		.dropdown-menu {
			z-index: 1000;
		}

		.navbar-collapse {
			border: none;

			@media screen and (min-width: $tablet) {
				padding: 0;
			}
		}

		.navbar-nav {
			@media screen and (min-width: $tablet + 1px) {
				width: 100%;
			}

			@media screen and (max-width: $tablet) {
				margin: 0 -15px;
			}

			// Menu chính
			> li {
				&.current {
					color: white;
					background-color: $second-color;

					> a {
						background-color: $second-color;
					}
				}

				&:hover {
					@media screen and (min-width: $tablet + 1px) {
						color: white;
						background-color: $second-color;
					}

					> a {
						@media screen and (min-width: $tablet + 1px) {
							background-color: $second-color;
						}
					}
				}

				&.open {
					> a {
						@media screen and (max-width: $tablet) {
							background-color: $primary-color !important;
						}
					}
				}

				// Menu level 1
				> a {
					padding: 5px 0;
					line-height: 26px;
					text-shadow: none;
					color: white;
					text-transform: uppercase;
					margin: 0 12px;
					background-color: $primary-color;
					font-weight: bold;
					font-size: 18px;
					font-family: TradeGothicLTBold20;

					@media screen and (max-width: $tablet) {
						margin: 0;
					}

					@media screen and (min-width: $tablet + 1px) {
						margin: 0 12px;
					}

					&.current,
					&:hover {
						@media screen and (min-width: $tablet + 1px) {
							color: white;
							background-color: $second-color !important;
						}
					}

					@media screen and (max-width: $tablet) {
						padding: 5px 15px;
						text-align: left;
					}

					span {
						font-family: TradeGothicLTBold20;
					}
				}

				.dropdown-toggle {
					a:focus {
						background-color: $primary-color;
					}
				}

				.submenu-caret-wrapper {
					display: inline-block;

					@media screen and (max-width: $tablet) {
						padding: 0px 10px !important;
						float: right;
						display: block;
					}

					.caret {
						margin: 0 0 0 10px;

						@media screen and (max-width: $tablet) {
							margin: 0;
							height: auto;
							line-height: inherit;
							padding: 0;
							display: inline-block;
						}
					}
				}

				// Từ submenu trở đi
				li {
					border-radius: 0;
					border-bottom: 1px solid white;
					width: 100%;

					&:hover,
					&:focus {
						> a {
							@media screen and (min-width: $tablet + 1px) {
								background-color: $primary-color;
								background-image: none;
							}
						}
					}

					@media screen and (max-width: $tablet) {
						border-bottom: 1px solid white;
						float: none;
						display: inherit;

						&:last-child {
							border-bottom: none;
						}
					}

					a {
						line-height: 25px;
						margin: 0;
						box-sizing: border-box;
						padding: 3px 35px 3px 15px;
						font-weight: bold;
						border: none;
				    font-size: 17px;
						font-family: TradeGothicLTBold20;

						@media screen and (min-width: $tablet + 1px) {
							padding: 3px 15px;
						}

						&:hover,
						&:focus {
							background-color: $primary-color;
							background-image: none;
						}

						@media screen and (max-width: $tablet) {
							padding: 5px 15px 0 30px;
						}

						.submenu-caret-wrapper {
							@media screen and (min-width: $tablet + 1px) {
								display: none;
							}
						}

						span {
							font-family: TradeGothicLTBold20;
						}
					}
				}

				ul {
					border-radius: 0;
					border: none;
					padding: 0;
					margin: 0;
					background-color: $second-color;
					color: white;
					box-shadow: none;
				}
			}
		}

		.language {
			float: right;

			&:hover {
				background-color: transparent !important;
			}

			ul {
				float: left;
				 background-color: transparent !important;
				 margin: 9px 0 !important;

				li {
					float: left;
					margin-right: 5px;
					width: auto !important;
					border: none;

					&:hover {
						a {
							background-color: transparent;
						}
					}

					a {
						background-image: url(img/icon.png) !important;
						background-repeat: no-repeat;
						background-color: transparent;
						width: 26px;
						height: 18px;
						float: left;

						&:hover{
							text-decoration: none;
							background-color: transparent;
						}
						&.vi{
							background-position: -6px -122px;
						}
						&.en{
							background-position: -34px -122px;
						}
						&.fr{
							background-position: -65px -122px;
						}
					}
				}
			}
		}
	}
}

.navbar-nav>li:last-child{
	border-right: none;
}

.nav>li>a:focus, .nav>li>a:hover {
	text-decoration: none;
	color: #fff;
}
.navbar-oneweb .navbar-nav>.active>a, .navbar-oneweb .navbar-nav>.active>a:focus, .navbar-oneweb .navbar-nav>.active>a:hover {
}
.navbar-nav.navbar-right li a {
	color: #ffe010;
	padding-left: 0;
}
.navbar-nav > .dropdown > .dropdown-menu > .dropdown >ul.dropdown-menu {
	left: 100%;
	top: 0;
}
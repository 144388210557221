body {
	overflow-x: -moz-hidden-unscrollable;
	line-height: 20px;
	font-size: 14px;
	margin: 0;
	padding: 0;
	color: $text-color;


	> .embed-responsive {
		padding: 0;
		display: none;
	}

	#wrapper {
		float: left;
		width: 100%;

		&.home {
			#content {
				header.title {
					border-bottom: none;
					padding: 5px 0;
					margin: 0;
					line-height: 20px;

					h1,
					h2 {
						font-weight: bold;
						font-size: 15px;
					}
				}
			}
		}

		#content {
			min-height: calc(100vh - 312px);
			margin: 0 auto 8px auto;
			padding: 10px 0 0 0;
			background-color: white;
			box-shadow: 0 4px 5px 3px #ccc;

			header.title {
				border-bottom: 1px solid $third-color;
				color: $third-color;
				padding: 5px 0;
				margin: 0 0 15px 0;
				line-height: 20px;

				h1,
				h2 {
					font-weight: bold;
					font-size: 15px;
				}
			}

			.read {
				.des {
					text-align: justify;
				}
			}

			.box-other {
				float: left;
				width: 100%;
			}

			.box_post {
				@media screen and (max-width: $tablet) {
					height: auto;
				}
			}
		}
	}


	a {
		color: $primary-color;

		&:hover,
		&:active,
		&:focus {
			text-decoration: none !important;
			color: $primary-color;
		}
	}

	#call {
		position: fixed;
		bottom: 15px;
		display: block;
		left: 15px;
		z-index: 123;

		@media screen and (min-width: $tablet + 1px) {
			display: none !important;
		}

		.hotline {
			width: 50px;
		}
	}

	// Sản phẩm chạy ngang
	.owl-carousel {
		float: left;
		width: 100%;

		.owl-dots {
			.owl-dot {
				&.active {
					span {
						background-color: $second-color;
					}
				}
			}
		}

		.owl-item {
			&.active:last {
				border: 1px solid red;
				.hover-border {
					width: calc(100% - 3px);
				}
			}
		}

		.owl-nav {
			position: absolute;
			top: calc(50% - 50px);
			left: 0;
			width: 100%;
			display: block !important;

			.owl-prev {
				position: absolute;
				left: 22px;
				background: transparent;
				margin: 0;
				padding: 0;

				@media screen and (max-width: $mobile) {
					left: 15px;
					right: auto;
				}

				&:hover {
					background: transparent;
				}

				.glyphicon {
					color: #777;
				}

				.owl-left {
					background: url('img/owl-left.png');
					background-repeat: no-repeat;
					width: 40px;
					height: 40px;
					background-size: 35px auto;
					background-position: 0px;
					margin: 0;
					padding: 0;
				}
			}

			.owl-next {
				position: absolute;
				right: 22px;
				background: transparent;
				margin: 0;
				padding: 0;

				@media screen and (max-width: $mobile) {
					right: 15px;
				}

				&:hover {
					background: transparent;
				}

				.glyphicon {
					color: #777;
				}

				.owl-right {
					background: url('img/owl-right.png');
					background-repeat: no-repeat;
					width: 40px;
					height: 40px;
					background-size: 35px auto;
					background-position: 0px;
					margin: 0;
					padding: 0;
				}
			}
		}
	}

	#sidebar_left {
		@media screen and (max-width: $tablet) {
			display: none;
		}
	}

	.share-socials {
		margin-top: 15px;
		float: left;
		margin-bottom: 10px;

		> div {
			float: left !important;
			margin-right: 5px !important;
		}

		.fb-like {
			vertical-align: top;
		}

		.email {
			img {
				height: 20px;
				margin-right: 5px;
			}
		}

		.social {
			.addthis_counter.addthis_pill_style a.addthis_button_expanded {
				width: 39px !important;
			}
		}

		.embed-responsive {
			padding-bottom: 20px;
		}
	}

	.quote-gallery {
		@media screen and (max-width: $tablet) {
			height: auto;
		}
	}
}

// Phân trang
.paginator {
	line-height: 20px;
	display: block;
	padding-top: 10px;
	text-align: right;
	float: left;
	width: 100%;
}

.paginator span {
	margin: 1px;
}
.paginator span a{
	color: #333;
	padding: 2px 5px;
	border: 1px solid #ccc;
}
.paginator span.current,#content .paginator a:hover {
	font-weight: bold;
	padding: 2px 5px;
	border: 1px solid #ccc;
	color: #cf0000;
}

.paginator a.disabled-page {
	color: #777;
}

.paginator a.disabled-page:hover {
	text-decoration: none;
	cursor: default;
}

// Lên đầu trang
#back-top {
	bottom: 25px;
	right: 15px;
	position: fixed;
	z-index: 9999;
}

#back-top a {
	color: #BBBBBB;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 26px;
	display: block;
	text-align: center;
}

#back-top a:hover {
	color: #000000;
}

#back-top span {
	background: darken($primary-color, 10)  url("img/up-arrow.png") no-repeat scroll center center;
	border-radius: 5px;
	width: 45px;
	height: 45px;
	display: block;
	margin-bottom: 7px;
}

#back-top a:hover span {
	background-color: darken($primary-color, 5) ;
}

.tailormade {
	@media screen and (max-width: $tablet) {
		display: none;
	}
}
.home {
	.product-grid-item {
		margin: 10px 0 0 0;
	}
}

.list_product {
	.heading{
		margin-top: 0;

		.head-label {
			padding-right: 0;
		}
	}

	.thumb {
		text-align: center;
	}

	.product-grid-item {
		.name {
			a {
				font-weight: bold;
			}
		}

		.filter-name {
			font-weight: normal;
		}
	}

	#products-wrapper {
		float: left;
		width: calc(100% + 30px);
		clear: both;
	}
}

.product-grid-item {
	margin-bottom: 0;
	z-index: 10;

	.name {
		padding: 0;
		margin: 10px 0 10px 0;
		text-transform: uppercase;
		color: $text-color;
		font-weight: bold;

		a {
			text-transform: uppercase;
			color: $text-color;
		}
	}

	.img-wrapper {
		display: inline-block;
		overflow: hidden;

		img {
			transition: all .8s ease-in-out;

			&:hover {
				transform:scale(1.05);
				-ms-transform:scale(1.05); /* IE 9 */
				-moz-transform:scale(1.05); //Firefox
				-webkit-transform:scale(1.05); /* Safari and Chrome */
				-o-transform:scale(1.05); /* Opera */
			}
		}
	}

	.infos {
		list-style: none;
		margin: 0;
		padding: 0;
		margin-bottom: 10px;
		display: none;

		.detail {
			float: right;

			.glyphicon-chevron-right {
				color: $second-color;
				font-size: 8px;
				vertical-align: top;
				margin-top: 5px;
			}
		}

		.contact-link {
			&:hover {
				color: #e32a2d;
			}
		}

		.filter-name {
			font-weight: bold;
		}
	}
}

.detail_product {
	header.name {
		border-bottom: 2px solid $primary-color;
		margin-bottom: 20px;

		h2 {
			text-transform: uppercase;
			font-size: 18px;
		}
	}

	#content {
		.info_top {
			padding: 10px 0;
		}
	}

	.images {
		padding-left: 0;

		@media screen and (max-width: $mobile) {
			padding-right: 0;
		}
	}

	.info {
		margin: 0;

		@media screen and (max-width: $mobile) {
			width: 100%;
			padding: 0;
			margin-top: 15px;
		}

		.product-infos {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				border-bottom: 1px dotted $divider-color;
				margin-bottom: 5px;
				padding-bottom: 5px;

				&:last-child {
					margin-bottom: 0;
				}

				span {
					font-weight: bold;
				}
			}
		}

		.summary {
			width: 100%;
			float: left;
			border-bottom: 1px solid $divider-color;
			padding: 2px 0;
			box-sizing: border-box;
			margin: 10px 0;
		}

		.hotline-wrapper {
			float: left;
			width: 100%;
			padding: 20px 0 0 0;

			.hotline {
				background: url('img/hotline.png') 0 8px no-repeat;
				margin: -2px 0 0 0;
				padding: 0 0 8px 32px;
				font-weight: bold;
				display: inline-block;
				background-size: 140px;
				float: left;

				a {
					color: $primary-color;

					&:hover {
						text-decoration: none;
					}
				}

				.s247 {
					float: left;
					font-weight: normal;
					font-size: 13px;
					line-height: 13px;
					margin: 0 0 1px 10px;
					color: #92642e;
				}

				.tel {
					float: left;
					clear: left;
					font-size: 17px;
					line-height: 17px;
				}
			}

			.catalog-link {
				float: right;

				.download-catalog {
					color: $text-color;
					padding: 5px 0 5px 10px;
					border-radius: 5px;
					float: left;
				}

				.pdf-icon {
					width: 30px;
					float: left;
					margin: 0;
				}
			}
		}

		.btn-print {
			float: left;
			margin: 15px 0 0 10px;
		}
	}

	.product_des {
		float: left;
		width: 100%;
		margin-top: 15px;

		.tab_container {
			width: 100% !important;

			.tab_content {
				padding: 10px;

				img {
					max-width: 100%;
					height: auto !important;
				}

				&#tab_comment {
					#tab-selector-comments {
						margin-top: 15px !important;
						clear: both;
					}

					ul.tabs li {
						background-color: white !important;
						color: $text-color !important;

						&.active {
							border-color: $primary-color !important;
							border-bottom: none !important;
						}

						a {
							color: $text-color !important;
						}
					}

					ul.tabs li a:hover {
						background-color: white !important;
						color: $text-color !important;
					}

					.tab-product-des {
						display: block !important;
					}

					.tab_container {
						width: 100% !important;
						border: none !important;

						.tab_content {
							padding: 10px 0 0 0;
							float: left;
							width: 100%;

							img {
								max-width: 100%;
								height: auto !important;
							}

							&#tab_comment {
								.embed-responsive-16by9 {
									padding-bottom: 25%;
								}

							}
						}
					}

					#comments-gp {
						width: 100% !important;
						height: auto !important;

						iframe {
							width: 100% !important;
						}
					}

					.facebook_comment_icon {
						width: 15px;
						vertical-align: top;
						margin-top: 5px;
					}

					.google_comment_icon {
						width: 15px;
						vertical-align: top;
						margin-top: 5px;
					}
				}
			}
		}
	}

	.viewed {
		float: left;
		width: 100%;
		margin-top: 15px;

		.title {
			float: left;
			width: 100%;
			font-weight: bold;
			margin-bottom: 10px;
			font-size: 16px;
		}
	}

	.choose-date-scroller {
		@media screen and (min-width: $tablet + 1px) {
			display: none;
		}
	}

	.owl-carousel {
		.owl-stage {
			@media screen and (min-width: $tablet + 1px) {
				transform: translate3d(0px, 0px, 0px) !important;
			}
		}

		.owl-nav {
			top: -7px;

			@media screen and (min-width: $tablet + 1px) {
				display: none !important;
			}

			.owl-prev {
				left: 0;
			}

			.owl-next {
				right: 0;
			}
		}
	}

	.social {
		float: left;
		width: 100%;
	}

	.recaptcha_nothad_incorrect_sol.recaptcha_isnot_showing_audio {
		float: none;

		@media screen and (max-width: $tablet) {
			margin-left: 0;
		}
	}

	#question_answer {
		float: left;
		width: 100%;
		padding: 0;

		#c_contact {
			float: left;
			width: 100%;

			.c_contact_top {
				float: left;
				width: 100%;

				.contact_form_right {
					float: left;
					width: 100%;
				}
			}
		}
	}

	#c_contact .contact_form_right {
		float: left;
		width: 100%;

		@media screen and (max-width: $tablet) {
			padding-left: 0;
		}
	}
}

.box_product {
	padding: 0;
	position: relative;
	box-shadow: 0 1px 4px #d4d4d4;
	float: left;
	margin-bottom: 15px;
	float: left;
	text-align: center;
	border: 1px solid #d4d4d4;

	.thumb {
		display: inline-block;
		float: none;

		a img {
			float: left;
			border-radius: 10px;
			max-width: 100%;
			padding: 5px;
		}
	}
}

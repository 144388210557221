.booking_form {
	@media screen and (max-width: $tablet) {
		padding: 0;
	}
	table {
		width: 100%;
		max-width: 500px;

		th,
		td {
			@media screen and (max-width: $tablet) {
				display: block;
				float: left;
				width: 100%;
			}
		}

		.gender {
			width: 100px;
		}
	}
}
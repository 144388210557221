.home {
	.owl-stage-outer {
		width: calc(100% + 6px);

		.hover-border {
			border: 1px solid white;
			margin-bottom: 3px;
			box-shadow: 3px 2px 3px white;

			&:hover {
				border: 1px solid $divider-color;
				box-shadow: 3px 2px 3px #c1c1c1;
				width: calc(100% + 1px);
				padding-right: 16px;
			}
		}
	}

	#home-news {
		.heading {
			text-transform: uppercase;
			color: $text-color;
			border-bottom: 1px solid $primary-color;
			margin: 0 0 15px 0;
			float: left;
			width: 100%;
			font-weight: bold;
			padding: 5px 0;
			font-size: 17px;

			a {
				color: $text-color;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.post {
			margin-bottom: 12px;

			.content {
				border-bottom: 1px dashed $primary-color;

				.thumb {
					a {
						float: left;
					}
				}

				.infos {
					background-color: white;
					padding: 0;
					float: left;

					.name {
						margin: 0 0 5px 0;
						float: left;
						width: 100%;
						font-weight: bold;

						a {
							color: $text-color;
							width: 100%;

							&:hover {
								color: $text-color;
							}
						}
					}

					.datetime {
						color: #7d7d7d;
						margin-bottom: 5px;
						float: left;
						width: 100%;
						display: none;
					}

					.summary {
						margin: 0 0 12px 0;
					}

					.readmore {
						display: none;
					}
				}
			}
		}

		.videos {
			@media screen and (min-width: $tablet + 1px) {
				padding-top: 28px;
			}

			.big-video {
				@media screen and (min-width: 992px) {
					padding-right: 0;
				}
			}

			.small-videos {
				@media screen and (min-width: 992px) {
					overflow: auto;
				}

				@media screen and (max-width: $tablet) {
					white-space: nowrap;
					overflow: auto;
					margin-top: 10px;
				}

				.item-small-video {
					margin-bottom: 10px;
					padding: 0;
					position: relative;

					@media screen and (max-width: $tablet) {
						display: inline-block;
						float: none;
					}

					.thumb-yt {
						width: 100%;
					}

					.youtube-play {
						background: url('img/youtube_preview.png') 0 0 no-repeat;
						position: absolute;
						top: calc(50% - 10px);
						left: calc(50% - 20px);
						height: 40px;
						width: 40px;
						background-size: 40px;
					}
				}
			}

			.channel {
				padding-top: 10px;

				.embed-responsive {
					padding-bottom: 24px;
				}

				.name {
					font-weight: bold;
				}

				#___ytsubscribe_0 {
					float: right !important;

					@media screen and (max-width: $mobile) {
						float: left !important;
					}
				}
			}

			.h-line {
				margin: 15px 15px 15px 15px;
				border-top: 1px solid $second-color;
				box-sizing: border-box;
				width: calc(100% - 15px);
				padding: 10px 0;
			}

			.fanpage {
				border-right: 1px solid $divider-color;
			}

			.socials {
				text-align: right;

				@media screen and (max-width: $mobile) {
					margin-top: 10px;
				}

				#___plus_0 {
					float: left !important;
					clear: both !important;

					.embed-responsive-16by9 {
						padding-bottom: 20px;
						width: 100px;
						text-align: left;
					}
				}

				#___plusone_0 {
					float: left !important;
					clear: both !important;
					margin-top: 10px !important;

					.embed-responsive-16by9 {
						padding-bottom: 20px;
					}
				}
			}
		}
	}

	// Đối tác khách hàng
	#our-customer {
		.customer-title {
			text-align: center;
			color: $primary-color;
			text-transform: uppercase;
			border-bottom: 3px solid $primary-color;
			font-size: 16px;
			font-weight: bold;
			padding: 0 0 6px 0
		}
	}

	.box_banner {
		margin-bottom: 10px;
		position: relative;
		padding: 0 30px;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			overflow: hidden;
			padding: 0;
			height: 160px !important;

			li {
				float: left;
				border: 1px solid $divider-color;
				margin: 0 5px;
				text-align: center;

				img {
					display: inline-block;
				}
			}
		}

		.btn-prev {
			background: url('img/owl-left.png');
			background-repeat: no-repeat;
			width: 35px;
			height: 30px;
			background-size: 9px auto;
			background-position: 15px 0px;
			position: absolute;
			left: 0;
			top: 58px;
			cursor: pointer;
		}

		.btn-next {
			background: url('img/owl-right.png');
			background-repeat: no-repeat;
			width: 35px;
			height: 30px;
			background-size: 9px auto;
			background-position: 15px 0px;
			position: absolute;
			right: 0;
			top: 58px;
			cursor: pointer;
		}

		.caroufredsel_wrapper {
			width: 100% !important;
		}
	}
}

.home,
.list_product {
	.heading {
		border-bottom: 2px solid $primary-color;
		margin: 20px 0 5px 0;
		float: left;
		width: 100%;

		&.best-buy {
			@media screen and (max-width: $tablet) {
				margin-top: 0;
			}
		}

		.head-label {
			padding-left: 0;
			padding-right: 24px;

			@media screen and (max-width: $tablet) {
				padding-right: 0
			}

			.glyphicon-menu-hamburger {
				float: right;
				margin-top: 5px;

				@media screen and (min-width: $tablet + 1px) {
					display: none;
				}
			}
		}

		.best-buy {
			text-transform: uppercase;
			color: $primary-color;
			font-weight: bold;
			float: left;
			background-color: $primary-color;
			padding: 4px 8px 4px 38px;
			background-repeat: no-repeat;
			background-size: 13px;
			background-position: 7px 5px;
			background-image: url('img/best-seller.png');
			margin: 0;
			color: white;
			width: 100%;

			.glyphicon {
				float: right;
				margin: 3px 0 0 0;
				font-size: 15px;

				@media screen and (min-width: $tablet + 1px) {
					display: none;
				}
			}
		}

		.category {
			text-transform: uppercase;
			color: $primary-color;
			font-weight: bold;
			float: left;
			background-color: $primary-color;
			padding: 4px 0 4px 10px;
			margin: 0;
			color: white;
			width: 100%;

			.category-icon {
				margin: 0 10px 0 0;
			}

			a {
				color: white;
				letter-spacing: 0.5px;
				word-spacing: 1px;
				vertical-align: middle;

				&:hover {
					color: white;
				}
			}

			.glyphicon {
				float: right;
				margin: 5px 8px 0 0;
				font-size: 15px;

				@media screen and (min-width: $tablet + 1px) {
					display: none;
				}
			}
		}

		.filter-best-buy {
			@media screen and (max-width: $tablet) {
				margin: 20px 0;
				display: none;
			}

			.filter-bar {
				list-style: none;
				margin: 3px 0 0 0;
				padding: 0;
				float: right;

				li {
					display: block;
					padding: 0 5px 0 0;
					margin: 0 5px 0 0;
					float: left;
					border-right: 1px solid black;
					line-height: 14px;
					font-weight: bold;
					cursor: pointer;
				}

				.owl-stage-outer {
					margin: 2px 0 0 0;

					.owl-item {
						&:last-child {
							li {
								border-right: none;
							}
						}
					}
				}

				.owl-nav {
					top: -13px;

					@media screen and (min-width: $tablet + 1px) {
						display: none !important;
					}

					&.show {
						display: block !important;
					}

					.owl-prev,
					.owl-next {
						background: transparent;

						i {
							font-size: 11px;
							color: #3c3333;
						}
					}

					.owl-prev {
						left: -12px;
					}

					.owl-next {
						right:  -12px;
					}
				}
			}
		}

		.filter-category {
			@media screen and (max-width: $tablet) {
				width: 100%;
				display: none;
			}

			.filter-bar {
				list-style: none;
				margin: 0;
				padding: 0;
				float: right;

				> li {
					display: block;
					padding: 0;
					margin: 0;
					float: left;

					@media screen and (max-width: $tablet) {
						width: 50%;
					}

					&:last-child {
						border-right: none;
						padding-right: 0;
					}

					.filter-btn {
						background: transparent !important;
						border: none;
						text-shadow: none;
						box-shadow: none;
						cursor: pointer;
						font-weight: bold;
						padding: 4px 7px;
						color: $text-color !important;

						@media screen and (max-width: $tablet) {
							width: 100%;
							text-align: left;
						}

						&:active {
							text-shadow: none;
							box-shadow: none;
						}

						.caret {
							color: $third-color;

							@media screen and (max-width: $tablet) {
								float: right;
								margin-top: 10px;
							}
						}
					}

					ul {
						li {
							a {
								cursor: pointer;
								width: calc(100% - 26px);
								clear: none;
								float: left;

								&:hover {
									background: white;
								}
							}

							.filter-condition {
								float: left;
								width: 20px;
								margin: 0;
								display: inline-block;
								height: 26px;
								display: none;
							}

							.checkbox {
								height: 26px;
								padding-top: 5px;
								float: left;
								margin: 0;

								&.checkbox-circle label::before {
									background-color: white;
									border-color: $primary-color;
								}
								&.checkbox-circle label::after {
									color: $primary-color;
									top: -1px;
								}
							}
						}
					}
				}
			}
		}
	}

	#pre-footer-inner {
		.span6 {
			@media screen and (max-width: $tablet) {
				width: 100%;
			}
		}
	}
}

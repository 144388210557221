#header_top {
	background-color: $second-color;
	color: white;

	.navbar-toggle {
		float: left;
		margin: 0;
		padding-left: 0;

		@media screen and (min-width: $tablet + 1px) {
			display: none;
		}

		.icon-bar {
			background-color: white;
			width: 15px;
			height: 1px;
		}
	}

	.navbar {
		float: left;
		width: 100%;
		min-height: 0;

		@media screen and (min-width: $tablet + 1px) {
			display: none;
		}

		.navbar-nav {
			li {
				a {
					padding-left: 0;
					padding-right: 0;
					color: white;
					text-shadow: none;
					text-align: left;
				}
			}
		}
	}

	.nav-top {
		@media screen and (max-width: $tablet) {
			float: left;
			margin: 0 0 0 5px;
			display: none;
		}

		ul {
			float: left;

			li {
				a {
					font-size: 13px;
					color: white;

					@media screen and (max-width: $tablet) {
						font-size: 12px;
					}
				}
			}
		}

		.line_nav_top {
			margin: 0 3px;
		}
	}

	.social-top {
		float: right;

		ul {
			list-style: none;
			float: right;
			margin: 0;
			padding: 0;

			> a {
				&:last-child {
					li {
						margin-right: 0;
					}
				}
			}

			li {
				width: 17px;
				height: 17px;
				float: left;
				margin: 7px 7px 7px 0;
				cursor: pointer;

				&.facebook {
					background-image: url('img/facebook.png');
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: 17px;
				}

				&.skype {
					background-image: url('img/skype.png');
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: 17px;
				}

				&.twitter {
					background-image: url('img/twitter.png');
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: 17px;
				}

				&.google_plus {
					background-image: url('img/google_plus.png');
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: 17px;
				}

				&.youtube {
					background-image: url('img/youtube.png');
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: 17px;
				}
			}
		}
	}
}

#header {
	padding: 10px 0 10px 0;
	margin-bottom: 0;
	margin: 0 auto;
	border-top: 8px solid $primary-color;
	float: left;
	width: 100%;
	background-color: white;

	@media screen and (max-width: $tablet) {
		padding: 10px 0;
	}

	> .container {
		position: relative;

		.top {
			position: absolute;
			z-index: 100;
			right: 0;
			top: -10px;
			padding-right: 22px;
			background: url('img/bg_nav_top_r.png') no-repeat right bottom;

			@media screen and (max-width: $tablet) {
				background: transparent;
				position: static;
			}

			ul {
				background: url('img/bg_nav_top.png') no-repeat left bottom;
				height: 30px;
				line-height: 24px;
				padding-left: 22px;

				@media screen and (max-width: $tablet) {
					background: transparent;
					float: left;
					padding: 0;
				}

				li {
					@media screen and (max-width: $tablet) {
						background-color: transparent;
					}

					&:first-child {
						> a {
							> span {
								@media screen and (max-width: $tablet) {
									padding-left: 0;
								}
							}
						}
					}

					&:last-child {
						> a {
							> span {
								@media screen and (max-width: $tablet) {
									border-right: none;
									padding-right: 0;
								}
							}
						}
					}

					span {
						@media screen and (max-width: $tablet) {
							border-right: 1px solid $primary-color;
							color: $primary-color;
						}
					}
				}
			}
		}

		.middle {
			float: left;
			width: 100%;

			@media screen and (max-width: $tablet) {
				position: static;
			}
		}
	}

	#h_support {
		margin: 30px -19px 5px 0;
		float: right;

		@media screen and (max-width: $tablet) {
			display: none;
		}
	}

	.logo {
		margin: 0;
		float: left;

		@media screen and (max-width: $tablet) {
			margin: 5px 0;
		}

		img {
			max-height: 60px;
			margin-top: 8px;

			@media screen and (max-width: $tablet) {
				max-height: 45px;
				float: left;
			}
		}
	}

	.searchbox-wrapper {
		text-align: right;
		margin: 13px 0 0 0;

		@media screen and (max-width: $tablet) {
			position: relative;
		}

		.banners {
			list-style: none;
			float: left;
			margin: 5px 0 0 0;
			padding: 0;

			@media screen and (max-width: 1200px) {
				display: none;
			}

			li {
				float: left;
				background-image: url('img/header_banner_icons.png');
				background-size: 75px;
				background-repeat: no-repeat;
				padding-left: 42px;

				&:nth-child(1) {
					background-position: 0 -87px;
					width: 230px;
				}

				&:nth-child(2) {
					background-position: 0 -42px;
					width: 232px;
				}

				&:nth-child(3) {
					background-position: 0 0;
					width: 200px;
				}

				.big-label {
					text-transform: uppercase;
					font-weight: bold;
					text-align: left;
					margin: 0;
					letter-spacing: 0.1px;
				}

				.small-label {
					text-align: left;
					font-size: 11px;
					line-height: 15px;
					margin: 0;
				}
			}
		}
	}

	.hotline {
		background: url('img/hotline.png') 0 6px no-repeat;
		margin: 5px 0 0 0;
		padding: 0 0 8px 32px;
		font-weight: bold;
		display: inline-block;
		background-size: 134px;

		@media screen and (max-width: $tablet) {
			position: absolute;
			top: 5px;
			left: calc(50% - 38px);
		}

		a {
			color: $primary-color;

			&:hover {
				text-decoration: none;
			}
		}

		.s247 {
			float: left;
			font-weight: normal;
			font-size: 12px;
			line-height: 12px;
			margin: 0 0 1px 10px;
			color: #92642e;
		}

		.tel {
			float: left;
			clear: left;
			font-size: 16px;
			line-height: 16px;
		}
	}

	.navbar-toggle {
		margin: 5px 0 0 0;
		@media screen and (min-width: $tablet + 1px) {
			display: none;
		}

		.icon-bar {
			background-color: $primary-color;
		}
	}

	#form-filter-basic {
		display: inline-block;
		position: relative;
		vertical-align: top;

		@media screen and (max-width: $tablet) {
			display: none;
		}

		.search-btn {
			margin: 10px 0 0 20px;
			padding: 0;
			background-color: white;
			display: inline-block;
			vertical-align: top;

			.glyphicon {
				cursor: pointer;
				color: $text-color;
				font-size: 15px;
				line-height: 30px;
				vertical-align: top;
			}

			@media screen and (max-width: $tablet) {
				display: none;
			}

			input {
				background: none;
				border: none;
			}
		}

		.search-input {
			position: absolute;
			top: 39px;
			right: 0;
			display: none;

			.submit {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				height: 26px;
				line-height: 26px;
				padding: 0 11px;
			}

			input {
				font-size: 13px;
				padding: 0 8px;
				height: 26px;
				border: 1px solid #a2a2a2;
				width: 220px;
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
			}
		}
	}
}